import axios from 'axios';
import store from '@/store';

/**
 * Create Axios instance for send api request
 *  support => GET, POST, PUT, PATCH, DELETE
 */ 
export const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Authorization': '{Bearer} 6a940db19aa51044515f075253364970a654daa30e6c550c49cd5e058544f219',
    'Content-Type':  'application/json',
    // 'X-api-key': process.env.VUE_APP_API_KEY,
  },
});


/**
 * Create Axios instance for file uploader
 */ 
 export const httpUpload = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Authorization': '{Bearer} 6a940db19aa51044515f075253364970a654daa30e6c550c49cd5e058544f219',
    'Content-Type': 'multipart/form-data',
    // 'X-api-key': process.env.VUE_APP_API_KEY,
  },
});

export const httpIns = () => {
  const header = {
    'Authorization': '{Bearer} ' + store.getters.token,
    'Content-Type':  'application/json',
    // 'X-api-key': process.env.VUE_APP_API_KEY,
  };
  // alert(store.getters.token);
  console.log(header);
  return axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: header,
  });
}

/**
 * create Axios instance for OTP provider
 */ 
 export const httpOTP = axios.create({
  baseURL: process.env.VUE_APP_OTP_APP_URL,
  headers: {
    'Content-Type': 'application/json',
    'api_key':      process.env.VUE_APP_OTP_APP_KEY,
    'secret_key':   process.env.VUE_APP_OTP_APP_SECRET,
  },
});
