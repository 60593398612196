<script setup>
// import dependencies
import {ref, computed} from 'vue';
import { useStore } from 'vuex';

// import components
import Logo from '@/components/core/layout/BeavermanLogoComp.vue';
import Nav  from '@/components/layout/sidebar/SidebarNavComp.vue';
import Icon from '@/components/core/icon/FontIconComp.vue';


// prepare template value
const store = useStore();
const currentUser = store.getters.currentUser;
const editProfilePath = ref('/user/' + currentUser.user_id + '/edit/');
const mobileToggle = computed(() => store.getters.getMobileMenuStatus );
const toggleMobileMenu = () => {
  store.commit('toggleMobileMenuStatus');
}
</script>


<template>
  <div class="sidebar">
    <Logo mode='white'></Logo>

    <a 
      @click.prevent="toggleMobileMenu"
      href="#" 
      class="sidebar__mobile__toggle"
    >
      <Icon :slug=" !mobileToggle ? 'bars' : 'remove' " font="la"></Icon>
    </a>
    <div 
      class="sidebar__mobile"
      :class="{
        active: mobileToggle
      }"
    >
      <div class="sidebar__member">
        <h4 class="sidebar__member__name">
          <router-link 
            :to="editProfilePath" 
            class="sidebar__member__link"
          >
            {{ currentUser.full_name }}
          </router-link>
        </h4>
        <p class="sidebar__member__role">ผู้ดูแลระบบ</p>
      </div>
  
      <Nav></Nav>
    </div>
  </div>
</template>
