<script setup>
import { defineProps } from 'vue';


const props = defineProps({
  title: {
    type: String,
    default: 'งานของท่านยังดำเนินการไม่ถึงขั้นตอนนี้'
  },
  text: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: 'alert-orange'
  }
});

const loadIcon = () => {
  return require('@/assets/images/icon/' + props.icon + '.svg')
}
</script>

<template>
  <div class="msg__block">
    <div class="icon">
      <img :src="loadIcon()" />
    </div>
    <h4 class="title">
      {{ title }}
    </h4>
    <p v-if=" text !== '' ">
      {{ text }}
    </p>
  </div>
</template>