
import { jobPostRoute }     from './job-post-route'
import { jobManageRoute }     from './job-manage-route'


import JobView from '@/views/job/JobDetailView.vue'

export const jobRoute = [
  // ...jobListRoute,
  ...jobPostRoute,
  ...jobManageRoute,
  
  {
    path: '/job',
    name: 'JobView',
    component: () => import('@/views/job/JobView.vue'),
    meta: { title: 'ระบบโครงการ' }
  },
  {
    path: '/job/view/:id',
    name: 'JobDetail',
    component: JobView,
    meta: {
      title: 'รายละเอียดโครงการ'
    }
  },

  {
    path: '/bill',
    name: 'AllJobBill',
    component: () => import('@/views/job/bill/BillView.vue'),
    meta: {
      title: 'การชำระเงิน'
    }
  },
  {
    path: '/bill/:bill_id',
    name: 'BillFormView',
    component: () => import('@/views/job/bill/BillFormView.vue'),
    meta: {
      title: 'แจ้งหลักฐานการชำระเงิน'
    }
  },
  {
    path: '/receipt/:bill_id',
    name: 'RecieptFormView',
    component: () => import('@/views/job/bill/RecieptFormView.vue'),
    meta: {
      title: 'อนุมัติการเบิกค่าแรง'
    }
  },
];