import axios from 'axios';

/**
 * create Axios instance for send request => GET, POST, PUT, PATCH, DELETE
 */ 
export const apiRequest = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': '{Bearer} ' + process.env.VUE_APP_AUTH_KEY,
  },
});

/**
 * create Axios instance for file uploader
 */ 
export const apiFileRequest = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': '{Bearer} ' + process.env.VUE_APP_AUTH_KEY,
  },
});