<script setup>
import ApproveStatus from './ApproveStatus.vue';
import { ref, defineProps, onMounted } from 'vue';

defineProps({
  data: Object
})
const loaded = ref(false);


/** Lift Cycle ----------- */
onMounted(() => {
  loaded.value = true
})
</script>

<template>
  <div
    v-if="loaded"
    class="status"
    >
    <ApproveStatus 
      title="ผู้ดูแลระบบ"
      :status="data.admin_approve != 0" 
    />
    <ApproveStatus 
      title="ผู้รับเหมา"
      :status="data.contractor_approve != 0" 
    />
    <ApproveStatus 
      title="ลูกค้า"
      :status="data.owner_approve != 0" 
    />
  </div>
</template>