<script setup>
import { ref, onMounted, defineProps } from 'vue'; //computed, 
import { toFloat } from '@/utilities/number';

const numeral = require('numeral');

const props = defineProps({
  index: Number,
  parent: String,
  data: Object,
  cont: Object,
  editable: {
    type: Boolean,
    default: true
  }
});
const item = ref([]);
const childParent = ref('');


/** Life Cycle -----------------------*/
onMounted(() => {
  item.value = props.data;
  console.log(props.data);

  if (props.parent === '-1') {
    childParent.value = '';
  } else if(props.parent !== '') {
    childParent.value = props.parent + '.';
    item.value.parent = childParent.value;
  }
})






/** Method -----------------------*/
/**
 * Generate Number Indent class
 */
 const numberIndentClass = () => {
  if(item.value.parent === undefined) return '';
  return 'pl--' + (item.value.parent.split(".").length - 1 )
}



const findPrice = (boq, index) => {
  let item = boq[ index ];
  
  if(item.child.length === 0) {
    return item.total;
  } else {
    return findChildPrice(item);
  }
}

const findChildPrice = (item, total = 0) => {
  item.child.forEach(childItem => {
    if(childItem.child.length === 0) {
      let itemTotal = 0;
      if(childItem.price_type == 1) {
        let mat_price = parseFloat(childItem.mat_price);
        let man_cost = parseFloat(childItem.man_cost);
        itemTotal = (isNaN(mat_price) || isNaN(man_cost)) ? 0 : (mat_price + man_cost) * childItem.value;
      } else {
        let cost_flat = parseFloat(childItem.net_price);
        itemTotal = isNaN(cost_flat) ? 0 : cost_flat;
      }
      total += parseFloat(Math.round(itemTotal * 100) / 100);
    } else {
      total += findChildPrice(childItem);
    }
  })

  return total;
}

const priceFormat = (val) => {
  val = toFloat(parseFloat(val));
  return numeral(val).format('0,0.00');
}
</script>

<template>
  <tr 
    v-for="(item, index) in item.child"
    :key="index"
  >
    <td>
      <div :class="numberIndentClass()">
        {{ index+1 }}
      </div>
    </td>
    <td>
      {{ item.name }}
      <div 
        v-if="item.detail !== ''"
        class="description"
      >
        {{item.detail}}
      </div>
    </td>
    <!-- ราคารวมที่ผู้รับเหมาเสนอ -->
    <td
        v-for=" c in cont " 
        :key="c.member_id"
        class="col--price"
      >
        <span>{{ priceFormat(findPrice(c.boq, index)) }} บาท</span>
      </td>
  </tr>
</template>