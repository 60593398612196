<script setup>
/**
 * This component for multiple file uploader
 * 
 * @param {section}   - member/job
 * @param {refer_id}  - member_id/job_id
 * @param {slug}      - file slug such as 'attach', 'survey', etc.
 */

// import vue library
import { ref, defineProps, onMounted } from 'vue';

// import service or utilities
import getFileList from '@/services/file/getFileList.js';
import uploadFile  from '@/services/file/uploadFile.js';

// import component
import GalleryItem from '@/components/display/file/gallery/GalleryItemComp.vue';
import Icon  from '@/components/display/icon/SVGIconComp.vue';


/**
 * prepare common variables for this component
 */
const props = defineProps({
  section: String,
  refer_id: String,
  slug: String
});

// ref variables for template
const loaded =  ref(false);
const files =  ref([]);
const uploading =  ref(true);
const countUploading = ref(0);
const uploadedFile = ref([]);



/**
 * Detect empty common props and get old file list
 */ 
onMounted( async () => {
  if ( props.section === undefined || 
       props.refer_id === undefined || 
       props.slug === undefined
    ) {
    alert('Multiple file uploader is not available.');
    return '';
  }

  // get uploaded file list from api
  uploadedFile.value = await getFileList(
                                props.section, 
                                props.refer_id, 
                                props.slug
                              );

  // if OK, stop 'disabled' input status
  loaded.value = true;
  uploading.value = false;
})

/**
 * On click browse file event handled function
 */
const addBrowseFile = () => {
  // if detect on uploading process, 
  // system will reject this process
  if(uploading.value === true) return;

  // detect empty file for upload
  if(files.value.files.length > 0) {
    // count uploading file
    countUploading.value = files.value.files.length;

    // change component status to 'uplaoding'
    uploading.value = true;

    // start upload files loop
    for (let i = 0; i < files.value.files.length; i++) {
      startUploadItem(files.value.files[i]);
    }
  }
}

/**
 * Start upload file item
 * @param {file} - file item data
 */
const startUploadItem = (file) => {
  uploadFile(
    file, 
    props.section, 
    props.refer_id, 
    props.slug
  )
    .then((res) => {
      // if this file item is uploaded status
      // decrease 'countUploading' value
      countUploading.value--;

      // add file result to list
      console.log(res.data);
      uploadedFile.value.push(res.data);

      // if 'countUploading' is zero,
      // clear file data and change component status to 'available'
      if(countUploading.value == 0) {
        files.value = [];//null;
        uploading.value = false;
      }
    });
}

</script>

<template>
  <div class="file__uploader">
    
    <!--
      List of file 
        - uploaded files
        - uploading files
    -->
    <div 
      v-if="loaded"
      class="gallery"
    >
      <div class="inner">
        <GalleryItem
          v-for="file in uploadedFile"
          :key="file.file_id"
          :data="file"
        />

        <div class="gallery__item gallery__remove">
          <div class="gallery__item__wrap">
            <!-- backgroundImage: 'url(' + data.uri + ')' -->
            <a 
              :style="{
              }"
              href="#" 
              @click.prevent="$refs.files.click()"
              class="gallery__item__link file__uploader__browse"
            >
              <img src="@/assets/images/scale/3-2.png">
              <Icon icon="file-attach"></Icon>
              <span>เลือกไฟล์</span>
            </a>
            <input 
              ref="files" 
              v-on:change="
                addBrowseFile()
              " 
              :disabled="
                uploading
              "
              accept="
                image/png, 
                image/jpg,
                image/jpeg
              "
              type="file"
              multiple
            >
          </div>
        </div>
      </div>
    </div>
    <!-- end: List of file -->
  </div>
</template>

<style scoped>
.file__uploader__browse {
  height: 160px;
}
.file__uploader__browse .icon {
  margin-top: 25px
}
</style>