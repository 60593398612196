<script setup>
import Input from '@/components/display/form/InputRound.vue';
import { ref, reactive, onMounted, defineProps, defineExpose } from 'vue';
import { useVuelidate }    from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { hasError } from '@/utilities/validate';
import addressDb from '@/vars/address.json';



const props = defineProps({
  modelValue: Object,
  addressLabel: {
    type: String,
    default: "ที่อยู่"
  }
});
const location = addressDb;
const state = reactive(props.modelValue);
const districts = ref([]);
const subDistricts = ref([]);
const v$ = useVuelidate({
  address:    { required },
  province:   { required },
  district:   { required },
  subDistrict:{ required },
  zipcode:    { required }
}, state);
defineExpose({ v$ }) /// define variable for parent component


// initial input
onMounted(() => {
  if (state.zipcode !== '') {
    let districtResult = location.find( (item) => {
      return item.name == state.province;
    });
    districts.value = districtResult.district;

    let subDistrictResult = districts.value.find( (item) => {
      return item.name == state.district;
    });
    subDistricts.value = subDistrictResult.sub;
  }
})


const provinceChange = () => {
  let result = location.find( (item) => {
    return item.name == state.province;
  });
  districts.value = result.district;

  // clear data
  state.district     = '';
  state.subDistrict  = '';
  state.zipcode      = '';
  subDistricts.value = [];
}

const districtChange = () => {
  let result = districts.value.find( (item) => {
    return item.name == state.district;
  });
  subDistricts.value = result.sub;

  // clear data
  state.subDistrict  = '';
  state.zipcode      = '';
}

const subDistrictChange = () => {
  let result = subDistricts.value.find( (item) => {
    return item.name == state.subDistrict;
  });
  state.zipcode = result.zipcode;
}
</script>


<template>
  <Input 
    :label="addressLabel"
    v-model="state.address"
    placeholder="ตัวอย่างเช่น 362 บ้านนาวัง หมู่ที่ 3 ..."
    :validate="v$.address"
    :required="true"
  />
  <div class="row">
    <div class="col col--2">
      <div 
        class="input__round"
        :class="hasError(v$.province)"
      >
        <label class="label">
          จังหวัด <span class="required">*</span>
        </label>
        <select 
          v-model="state.province"
          @change="provinceChange"
        >
          <option disabled value="">เลือกจังหวัด</option>
          <option 
            v-for="p in location"
            :value="p.name"
            :key="p.name"
          >
            {{ p.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col col--2">
      <div 
        class="input__round"
        :class="hasError(v$.district)"
      >
        <label class="label">
          อำเภอ/เขต <span class="required">*</span>
        </label>
        <select 
          v-model="state.district"
          @change="districtChange"
        >
          <option disabled value="">เลือกอำเภอ/เขต</option>
          <option 
              v-for="d in districts"
              :value="d.name"
              :key="d.name"
          >
            {{ d.name }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row row2">
    <div class="col col--2">
      <div 
        class="input__round"
        :class="hasError(v$.subDistrict)"
      >
        <label class="label">
          ตำบล/แขวง <span class="required">*</span>
        </label>
        <select 
          v-model="state.subDistrict"
          @change="subDistrictChange"
        >
          <option disabled value="">เลือกตำบล/แขวง</option>
          <option 
              v-for="sd in subDistricts"
              :value="sd.name"
              :key="sd.name"
          >
            {{ sd.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col col--2">
      <div 
        class="input__round input__round__disabled"
        :class="hasError(v$.subDistrict)"
      >
        <label class="label">
          รหัสไปรษณีย์ <span class="required">*</span>
        </label>
        <input 
          type="text"
          v-model="state.zipcode"
          disabled
        >
      </div>
    </div>
  </div>
</template>


<style scoped>
.row {
  margin-bottom: 16px;
}
input, select {
  width: 100%;
}
</style>


