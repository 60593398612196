<script setup>
import TimelineItem from '../../timeline/TimelineItem.vue';
import Avatar from '@/components/display/image/AvatarComp.vue';
import InputRound from '@/components/display/form/InputRound.vue';
import WarningCard from '@/components/job/module/JobWarningCard.vue'
import { DatePicker } from 'v-calendar'; //Calendar, 
import 'v-calendar/dist/style.css';

import { ref, reactive, onMounted, defineProps } from 'vue';
import ButtonRound from '@/components/display/form/ButtonRound.vue';
import { loading } from '@/utilities/display';
import { useRouter } from 'vue-router';
import { http } from '@/utilities/http';
import notiAddData from '@/services/noti/notiAddData'


const moment = require('moment');
moment.locale("th");


const router = useRouter();
const props = defineProps({ data: Object });
const loaded = ref(false);
const cont = ref([]);
const isAdd = ref(true);
const state = reactive({
  data: {
    survey_date: new Date(),
    survey_remark: '',
  }
});


/** Life Cycle ----------- */
onMounted( async () => {

  cont.value = props.data.cont.filter(item => item.owner_accept == 9);

  // console.log(props.data.survey_date);
  if(props.data.survey_date) {
    state.data.survey_date = moment(props.data.survey_date).toDate();
    isAdd.value = false;
  }
  state.data.survey_remark = props.data.survey_remark;
  loaded.value = true;
})



const onSaveAppointment = async () => {
  let data = {...state.data}
  data.survey_date = moment(state.data.survey_date).format('YYYY-MM-DD HH:mm:ss');

  loading();
  await http
        .patch('/job/' + props.data.job_id, data)
        .then(async () => { //res
          // console.log(res.data);
          // console.log(router);

          // if this is first save (add form)
          // send noti to owner and contractor
          if(isAdd.value) {
            await notiAddData(
              props.data.owner,
              props.data.job_id,
              'survey_appointment_created'
            );
          }
          router.go(0);
        })
        .catch(err => {
          console.log(err);
          alert('ไม่สามารถบันทึกข้อมูลได้ !!!')
          loading(false);
        });

}
</script>

<template>
  <div v-if="loaded">
    <WarningCard v-if="cont.length == 0" />
    <div v-else>
      <TimelineItem
        v-if="isAdd === true"
        :data="{
          title: 'เลือกวันนัดสำรวจหน้างาน',
          date: '',
          time: '',
          sub_title: '',
        }"
      >
        <form @submit.prevent="onSaveAppointment" class="form">
          <fieldset>
            <div class="mt--1">
              <label class="label"><strong>วันและเวลาสำรวจ</strong></label>
              <DatePicker 
                v-model="state.data.survey_date"
                mode="dateTime"
                is24hr
              />
            </div>
            <InputRound 
              label="หมายเหตุ"
              inputType="textarea"
              v-model="state.data.survey_remark" 
            />
            
            <div class="txt-right">
              <ButtonRound
                text="บันทึกข้อมูล"
                icon="save"
                class="btn--submit"
              />
            </div>
          </fieldset>
        </form>
      </TimelineItem>
      <!-- end role is customer -->

      <div v-else>
        <div class="txt-right">
          <ButtonRound
            @click.prevent=" isAdd = true"
            text="แก้ไขข้อมูล"
            icon="edit-file"
            class="btn--submit btn--s mt--1"
          />
        </div>
        <TimelineItem
          :data="{
            date: '',
            time: '',
            title: 'ลูกค้าเลือกวันสำรวจแล้ว',
            sub_title: moment(data.survey_date).format('วันที่ D MMMM YYYY เวลา HH.mm น'),
          }"
        >
          <p v-if=" data.survey_remark != '' ">
            {{data.survey_remark}}
          </p>
          
        </TimelineItem>
  
        <TimelineItem 
          :data="{
            date: '',
            time: '',
            title: 'เจ้าหน้าที่สำรวจหน้างาน',
            sub_title: '',
          }"
        >
          <p>ชื่อเจ้าหน้าที่นักสำรวจจากทาง Beaverman</p>
          <ul class="timeline__member">
            <li>
              <span class="timeline__member__item active">
                <Avatar :src=" data.ca_meta.avatar " />
                {{ data.ca_meta.full_name }}
  
                เบอร์โทร {{ data.ca_meta.phone }}
              </span>
            </li>
          </ul>
        </TimelineItem>
  
        <TimelineItem
          :data="{
            date: '',
            time: '',
            title: 'ผู้รับเหมาที่มีสิทธิเข้าสำรวจหน้างาน',
            sub_title: '',
          }"
        >
          <p>รายชื่อผู้รับเหมาที่มีสิทธิเข้าสำรวจหน้างาน ผู้รับเหมาที่ยืนยันจะเข้าสำรวจจะแสดงสถานะเป็นสีเขียว</p>
          <ul 
            v-if=" data.cont.length > 0 "
            class="timeline__member"
          >
            <li
              v-for=" member in cont"
              :key="member"
            >
              <span 
                href="#"
                class="timeline__member__item"
                :class="{
                  active: member.appointment_accept == 9,
                  cancel: member.appointment_accept == 1,
                  waiting: member.appointment_accept == 0,
                }"
              >
                <Avatar :src=" member.avatar " />
                {{ member.full_name }} 

                <span class="status--text">
                  (<span v-if="member.appointment_accept == 9">
                    ยืนยันเข้าสารวจหน้างาน
                  </span>
                  <span v-else-if="member.appointment_accept == 1">
                    ผู้รับเหมาปฏิเสธการเข้าสํารวจ
                  </span>
                  <span v-else>
                    ผู้รับเหมายังไม่ยืนยัน
                  </span>)
                </span>
              </span>
            </li>
          </ul>
        </TimelineItem>
      </div>
      <!-- is add === false -->
    </div>
  </div>

</template>

<style scoped>
.txt-right {
  text-align: right !important;
}
</style>