<script setup>
// import Avatar from '@/components/display/image/AvatarComp.vue';
import { ref, onMounted, defineProps } from 'vue';
// import { contentImage } from '@/utilities/image'


defineProps({ 
  data: Object,
  selected: Boolean
});
const loaded = ref(false);


/** Life Cycle ----------- */
onMounted(() => {
  loaded.value = true;
})
</script>

<template>
  <div v-if="loaded" class="cont__info">
    <figure class="logo">
      <img v-if="data.avatar != ''" :src="data.avatar" />
      <img v-else src="@/assets/images/scale/1-1.png" />
    </figure>
    <h4 class="name">
      <a href="#">{{ data.full_name }}</a>
    </h4>
    <!-- <img src="@/assets/images/member/verified-status.svg" class="mt--1" style="margin-bottom: 3px" /> -->
  </div>
</template>