import { http } from '@/utilities/http';

export default async function deleteFileItem(fileId) {
  let result = {};
  await http
        .delete('/file/item/' + fileId)
        .then((res) => {
          result = res.data;
        })
        .catch(() => {
          result = {
            status: 500,
            errorMessage: 'api error',
          }
        });
  return result;
}
