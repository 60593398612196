/**
 * Notification type slug to detail
 */
export const notiType = [
  // post job -------------
  { id: 10 , slug: 'job_approved' },

  // interest action -----------
  { id: 20 , slug: 'contractor_interest' }, // contractor select interest buttion
  { id: 22 , slug: 'contractor_cancel_interest' }, // contractor deselect interest button
  { id: 24 , slug: 'owner_interest' }, // owner select contractor to survey
  { id: 26 , slug: 'owner_cancel_interest' }, // owner deselect contractor to survey
  
  // Survey appointment action -----------
  { id: 30 , slug: 'survey_appointment_created' }, // survey appointment created by admin
  { id: 32 , slug: 'survey_appointment_canceled' }, // contractor canceled survey appointment
  { id: 38 , slug: 'survey_appointment_accepted' }, // contractor accepted survey appointment

  // survey info
  { id: 40 , slug: 'survey_info_published' }, // admin
  { id: 42 , slug: 'survey_info_approved' }, // customer

  // proposal
  { id: 50 , slug: 'proposal_approved' }, // admin
  { id: 52 , slug: 'proposal_select_winner' }, // customer

  // agreement
  { id: 60 , slug: 'agreement_admin_approved' }, // admin
  { id: 61 , slug: 'agreement_contractor_approved' }, // contractor
  { id: 62 , slug: 'agreement_customer_approved' }, // customer
  
  
  // progress
  { id: 70 , slug: 'progress_update' }, // admin
  { id: 80 , slug: 'withdraw_admin_approved' }, // admin
  { id: 81 , slug: 'withdraw_customer_approved' }, // customer
  { id: 85 , slug: 'withdraw_admin_canceled' }, // admin
  { id: 86 , slug: 'withdraw_customer_canceled' }, // customer
]