<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    default: ''
  },
})

const loadImage = () => {
  return require('@/assets/images/icon/' + props.icon + '.svg');
}


</script>

<template>
  <figure 
    class="icon icon__svg"
    :style="{
      backgroundImage: 'url(' + loadImage() + ')'
    }"
  >
    <img src="@/assets/images/scale/1-1.png" />
  </figure>
</template>


<style scoped>
.icon__svg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon__svg img {
  opacity: 0;
}
</style>