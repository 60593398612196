/**
 * Set body element bg color
 * default color is soft gray
 * 
 * @param {*} color
 */
export default function bodyBg(color = '') {
  switch(color) {
    case 'white':
      document.body.classList.add('bg--white');
    break;
    
    // clear to default bg
    default:
      document.body.classList.remove('bg--white');
    break;
  }
}

