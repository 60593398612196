
import store from '@/store';


/**
 * new display loading function 
 * 
 * @param {Boolean} status - default true
 */
 export const loading = (status = true) => {
  if(status) {
    store.commit('loadingShow');
  } else {
    store.commit('loadingHide');
  }
}