import jwt_decode from "jwt-decode";

export default {
  /**
   * Get logged in status. if logged in, return true
   */
  loggedIn(state) {
    return state.authToken !== '';
  },

  /**
   * @return token value
   */
  token(state) {
    return state.authToken;
  },

  /**
   * Get current logged in user
   * parse data from authToken (JWT).
   */
  currentUser(state) {
    if(state.authToken === '') return {};

    const token = jwt_decode(state.authToken);
    return token.data;
  },

  /**
   * Return global state for show/hide loading 
   */
  showLoading(state) {
    return state.loadingStatus;
  },


  /**
   * get mobile menu status
   */
   getMobileMenuStatus(state) {
    return state.mobileMenuStatus;
  },
}