/**
 * Get user list
 */
import { http } from '@/constant/http.js';

export default async function getUsers(params = {}) {
  let user = {
    count: 0,
    rows: []
  };

  // prepare query params
  if(params.limit === undefined) {
    params.limit = 10;
  }
  if(params.page == undefined) {
    params.page = 1;
  }

  // prepare query params
  // prepare query params
  await http
        .get('/user', { params })
        .then((res) => {
            user = res.data;
        })
        .catch(() => {
          alert('การดีงข้อมูลพบข้อผิดพลาด');
        })

  return user;
}