import { VueCookieNext } from 'vue-cookie-next'
VueCookieNext.config({ expire: '30d' });


export default {
  /**
   * set user session when refresh
   *  use this function because store will clear when refresh
   *  so we will use cookie save data too.
   */ 
   detectLogged(state) {
    let cookieName = process.env.VUE_APP_AUTH_COOKIE;
    let authToken = VueCookieNext.getCookie(cookieName);

    if(authToken === '' || authToken === null || authToken === 'undefined') {
      // console.log('Store -> Token is undefined');

      // remove cookie for defense some error
      VueCookieNext.removeCookie(cookieName);

      // clear authToken to empty too.
      state.authToken = '';
    } else {
      state.authToken = authToken;
    }
  },


  /**
   * Set user session. use when loading
   * 
   * @param {state}
   * @param {jwtToken} 
   */
   setAuthToken(state, jwtToken) {
    // Set JWT token to cookie
    VueCookieNext.setCookie(process.env.VUE_APP_AUTH_COOKIE, jwtToken, '30d');

    // initial 'logged in' common value to store
    this.commit('detectLogged');
  },


  /**
   * Logout function. 
   *  remove user session from cookie
   *  clear user session from store
   */
  logout(state) {
    state.authToken = '';
    VueCookieNext.removeCookie(process.env.VUE_APP_AUTH_COOKIE);
  },



  /**
   * Change loadingStatus to true
   */
  loadingShow(state) {
    state.loadingStatus = true;
  },

  /**
   * Change loadingStatus to false
   */
   loadingHide(state) {
    state.loadingStatus = false;
  },


  /**
   * get mobile menu status
   */
  setMobileMenuStatus(state, value) {
    return state.mobileMenuStatus = value;
  },
  toggleMobileMenuStatus(state) {
    return state.mobileMenuStatus = !state.mobileMenuStatus;
  },
};