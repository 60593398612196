/**------------------------------------------------------------------------
 * Main import
 *------------------------------------------------------------------------*/

// import core of vue and common library
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import common dependencies of this project
// and config before create app
import vClickOutside from "click-outside-vue3"
import { VueCookieNext } from 'vue-cookie-next'
VueCookieNext.config({
  expire: '30d',
  path: '/',
  domain: '',
  secure: '',
  sameSite: '',
})


/**------------------------------------------------------------------------
 * Core action before start app
 *------------------------------------------------------------------------*/

// Initial Vuex data after refresh
store.commit('detectLogged');



/**------------------------------------------------------------------------
 * Create App
 *------------------------------------------------------------------------*/
createApp(App)
  .use(store)
  .use(router)
  .use(vClickOutside)
  .use(VueCookieNext)
  .mount('#app');


