export const leaveWorkRoute = [
  {
    path: '/leave-work',
    name: 'LeaveWorkView',
    component: () => import('@/views/leave-work/UserView.vue'),
    meta: { title: 'ผู้รับเหมาทิ้งงาน' }
  },
  {
    path: '/leave-work/:id',
    name: 'LeaveWorkItemView',
    component: () => import('@/views/leave-work/UserAddView.vue'),
    meta: { title: 'รายละเอียดผู้รับเหมาทิ้งงาน' }
  },
];