<script setup>
import VueEasyLightbox from 'vue-easy-lightbox';

import { ref, onMounted, defineProps } from 'vue';


const props = defineProps({ 
  gallery: Object 
});
const loaded = ref(false);
const images = ref([]);
const popupIndex = ref(0);
const popupVidible = ref(false);


/** Lift Cycle ----------- */
onMounted(() => {
  props.gallery.forEach(g => {
    images.value.push(g.uri);
  })
  loaded.value = true;
})


/** Method ----------- */
const showPopup = (index) => {
  popupIndex.value = index;
  popupVidible.value = true
}

const hidePopup = () => {
  popupVidible.value = false
}

</script>

<template>
  <ul v-if=" loaded " class="timeline__gallery">
    <li
      v-for="(image, index) in gallery"
      :key="image"
    >
      <a href="#" @click.prevent="showPopup(index)">
        <figure :style="{ 'background-image': 'url(' + image.uri + ')' }">
          <img src="@/assets/images/scale/3-2.png" />
        </figure>
      </a>
    </li>
  </ul>

  <VueEasyLightbox
    scrollDisabled
    escDisabled
    moveDisabled
    :visible="popupVidible"
    :loop="true"
    :imgs="images"
    :index="popupIndex"
    @hide="hidePopup"
  />
</template>