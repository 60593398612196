export const memberRoute = [
  {
    path: '/member',
    name: 'MemberView',
    component: () => import('@/views/member/MemberView.vue'),
    meta: { title: 'รายการผู้ใช้งาน' }
  },
  {
    path: '/member/contractor',
    name: 'MemberContView',
    component: () => import('@/views/member/ContView.vue'),
    meta: { title: 'รายการผู้รับเหมา' }
  },



  {
    path: '/member/add',
    name: 'MemberAddView',
    component: () => import('@/views/member/MemberAddView.vue'),
    meta: { 
      title: 'เพิ่มผู้ใช้งาน',
    }
  },
  {
    path: '/member/:id/profile',
    name: 'ProfileView',
    component: () => import('@/views/member/profile/ProfileView.vue'),
    meta: { 
      title: 'ข้อมูลผู้ใช้งาน',
    }
  },
  {
    path: '/member/:id/profile/contractor',
    name: 'ProfileContractorView',
    component: () => import('@/views/member/profile/ProfileContView.vue'),
    meta: { 
      title: 'ข้อมูลผู้รับเหมา',
    }
  },
  {
    path: '/member/:id/profile/contractor/edit',
    name: 'ContractorProfileEditView',
    component: () => import('@/views/member/profile/ContEditView.vue'),
    meta: { 
      title: 'แก้ไขข้อมูลผู้รับเหมา',
    }
  },
  {
    path: '/member/:id/contractor/view',
    name: 'ContractorView',
    component: () => import('@/views/member/ContractorView.vue'),
    meta: { 
      title: 'รายละเอียดผู้รับเหมา',
    }
  },



  {
    path: '/member/contractor/badge',
    name: 'MemberContBadgeView',
    component: () => import('@/views/member/badge/MemberContBadgeView.vue'),
    meta: { 
      title: 'การจัดการตราสัญลักษณ์ผู้รับเหมา',
    }
  },
  {
    path: '/member/contractor/badge/:id',
    name: 'MemberContBadgeEditView',
    component: () => import('@/views/member/badge/MemberContBadgeFormView.vue'),
    meta: { 
      title: 'แก้ไขตราสัญลักษณ์ผู้รับเหมา',
    }
  },


  {
    path: '/member/contractor/term',
    name: 'TermFormView',
    component: () => import('@/views/member/contractor/TermFormView.vue'),
    meta: { 
      title: 'เงื่อนไขผู้รับเหมา',
    }
  },
];