<template>
  <i :class="setClass()"></i>
</template>

<script>
export default {
  props: {
    font: {
      type: String,
      default: 'fa',
    },
    slug: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
  },
  
  methods: {
    setClass() {
      let output = this.font;
      let slugPrefix = this.font == 'fa' ? this.font : 'la';

      if(this.size != '') {
        output += ` ${slugPrefix}-${this.size}`;
      }

      if(this.slug != '') {
        
        output += ` ${slugPrefix}-${this.slug}`;
      }

      return output;
    }
  }
}
</script>