<script setup>
// import SectionTitle from '@/components/display/text/SectionTitle.vue'
import ContractorBoxInfo from '@/components/job/module/ContractorBoxInfo.vue'
import TimelineItem from '@/components/job/timeline/TimelineItem.vue';
import ProgressListPopup from './ProgressListPopup.vue';
// import Gantt from '@/components/job/gantt/GanttComp.vue';
// import VueEasyLightbox from 'vue-easy-lightbox';
// import ButtonRound from '@/components/display/form/ButtonRound.vue';

import { ref, onMounted, defineProps } from 'vue';
import { http } from '@/utilities/http';
import { loading } from '@/utilities/display';
import { useRouter } from 'vue-router';
import currentUser from '@/services/member/currentUser';
import nl2br from '@/utilities/format/nl2br';
import notiAddData from '@/services/noti/notiAddData'

const moment = require('moment');
moment.locale("th");


const router = useRouter();
const props = defineProps({ data: Object });
const loaded = ref(false);
const cont = ref([]);
const memberRole = currentUser('role');


/** Lift Cycle ----------- */
onMounted(() => {
  cont.value = [];

  props.data.progress.forEach(item => {
    item.format = {
      date: moment(item.post_date).format('DD.MM.YYYY'), 
      time: moment(item.post_date).format('HH:mm'),
      title: typeIdToTitle(item.type),
    }
    if(item.type == 10) {
      item.format.sub_title= moment(item.appointment).format('นัดตรวจงาน วันที่ D MMM YYYY  เวลา HH.mm น.');
    }
  })

  loaded.value = true;
})


/** Method ----------- */
const updateProgress = async (type) => {
  console.log(type);
  const job_id = props.data.job_id;
  loading()
  await http
        .post('/job/'+job_id+'/timeline', {type: type})
        .then(res => {
          console.log(res.data);
          console.log(router)
          router.push('/job/'+job_id+'/timeline/' + res.data.insert_id + '/' + typeIdToSlug(type))
        })
  loading(false)
}

const updateStatus = async (id, status) => {
  const job_id = props.data.job_id;
  loading()
  await http
        .post('/job/'+job_id+'/timeline/'+id, { admin_approve: status })
        .then( async (res) => {
          console.log(res.data);
          console.log(router)

          await notiAddData(
            props.data.owner,
            props.data.job_id,
            'withdraw_admin_' + (status == 9 ? 'approved':'canceled')
          );
          router.go(0)
        })
  loading(false)
}


const typeIdToTitle = (id) => {
  switch(parseInt(id)) {
    default:
      return 'เช็คอิน';
    case 2:
      return 'เช็คเอ้าท์';
    case 5:
      return 'อัพเดทความคืบหน้า';
    case 10:
      return 'เบิกงวดค่าแรง';
    case 11:
      return 'ความเห็นจาก Beaverman';
    case 15:
      return 'ความเห็นจากลูกค้า';
    case 20:
      return 'รายงานการแก้ไข';
  }
}

const typeIdToSlug = (id) => {
  switch(parseInt(id)) {
    default:
      return 'checkin';
    case 2:
      return 'checkout';
    case 5:
      return 'update-progress';
    case 10:
      return 'appointment';
    case 11:
      return 'beaverman-feedback';
    case 15:
      return 'customer-feedback';
    case 20:
      return 'revise-report';
  }
}
</script>

<template>
  <div 
    v-if=" loaded "
    class="job__step6"
  >
    
    <div 
      v-if="memberRole==='contractor'" 
      class="
        progress-button
      "
    >
      <a 
        @click.prevent="updateProgress(1)"
        href="#"
        class="pb-col"
      >
        <img src="@/assets/images/progress/checkin.svg" />
      </a>
      <a 
        @click.prevent="updateProgress(2)"
        href="#"
        class="pb-col"
      >
        <img src="@/assets/images/progress/checkout.svg" />
      </a>
      <div class="pb-col pb-col-last">
        <a 
          @click.prevent="updateProgress(5)"
          href="#"
        >
          <img src="@/assets/images/progress/update.svg" />
        </a>
        <a 
          @click.prevent="updateProgress(10)"
          href="#"
        >
          <img src="@/assets/images/progress/appointment.svg" />
        </a>
      </div>
    </div>

    <ContractorBoxInfo :info="data.winner_meta" class="had-slot">
      <div class="timeline__wrap">
        <TimelineItem
          v-for=" (item, index) in data.progress "
          :key="item"
          :data=" item.format "
          :isLast=" index === data.progress.length - 1 "
        >
          <div 
            v-if="item.type === 1"
            class="highligh-info"
          >
            <strong>จำนวนช่าง</strong> 
            {{ item.total_worker }} คน
          </div>
          <div 
            v-else-if="item.type === 10"
            class="highligh-info"
          >
            <strong>งวดงาน</strong>
            <ul class="bill--item">
              <li
                v-for="bill in item.relate_bill"
                :key="bill.id"
              >
                {{ bill.title }}
              </li>
            </ul>
          </div>


          <strong v-if="item.type == 1">
            แผนงานวันนี้
          </strong>
          <strong v-else-if="item.type == 2">
            ความคืบหน้าของงาน
          </strong>
          <strong v-else>
            รายละเอียด
          </strong>
          <p 
            v-html="nl2br(item.detail)"
          />

          <p class="mt--1">
            <strong v-if="item.type == 2">
              อุปสรรคหน้างาน
            </strong>
          </p>
          <p 
            v-html="nl2br(item.problem)"
          />
          <!-- <p v-html="nl2br(item.detail)"></p> -->

          <!-- gallery -->
          <ProgressListPopup
            v-if="item.gallery !== undefined"
            :gallery="item.gallery"
          />

          <div 
            v-if="item.type == 10" 
          >
            <div class="withdraw__status mb--2">
              <span v-if="item.admin_approve == 9" class="tsta">
                <img src="@/assets/images/job/btn/check.svg" /> 
                ผู้ดูแลระบบอนุมัติแล้ว
              </span>
              <span v-else-if="item.admin_approve == 1" class="tsta">
                <img src="@/assets/images/progress/cancel.svg" /> 
                ผู้ดูแลระบบไม่อนุมัติ
              </span>
              <span v-else class="tsta">
                <img src="@/assets/images/bill/bill-wait.svg" /> 
                รอผู้ดูแลระบบอนุมัติ
              </span>
              
              <span v-if="item.owner_approve == 9" class="tsta">
                <img src="@/assets/images/job/btn/check.svg" /> 
                ลูกค้าอนุมัติแล้ว
              </span>
              <span v-else-if="item.owner_approve == 1" class="tsta">
                <img src="@/assets/images/progress/cancel.svg" /> 
                ลูกค้าไม่อนุมัติ
              </span>
              <span v-else class="tsta">
                <img src="@/assets/images/bill/bill-wait.svg" /> 
                รอลูกค้าอนุมัติ
              </span>
            </div>
            
            <div 
              v-if="
                memberRole !== 'contractor' &&
                item.admin_approve == 0 &&
                item.owner_approve != 1
              "
              class="timeline-btn mt--2"
            >
              <a 
                @click.prevent="updateStatus(item.progress_id, 9)"
                href="#"
                class="pb-col"
              >
                <img src="@/assets/images/progress/btn-approve.svg" />
              </a>
                <!-- @click.prevent="updateStatus(item.progress_id, 1)" -->
              <!-- <a 
                href="#"
                class="pb-col"
              >
                
              </a> -->
              <a 
                @click.prevent="updateStatus(item.progress_id, 1)"
                href="#"
                class="pb-col"
              >
                <img src="@/assets/images/progress/btn-revise.svg" />
                <!-- <img src="@/assets/images/progress/btn-cancel.svg" /> -->
              </a>
            </div>
          </div>
        </TimelineItem>
      </div>
    </ContractorBoxInfo>
  </div>
</template>

<style scoped>
.progress-button {
  display: flex;
  margin-bottom: 20px;
  font-size: 0;
  line-height: 0;
}
.progress-button .pb-col {
  margin-right: 10px;
}
.timeline-btn {
  display: flex;
}
.timeline-btn a {
  margin-right: 10px;
}
.progress-button .pb-col-last a:first-child {
  display: block;
  margin-bottom: 5px;
}

.bill--item {
  margin-bottom: 10px;
  padding-left: 20px;
}
.bill--item li {
  list-style-type: disc;
}
</style>