<script setup>
// import dependencie
import { computed, defineProps } from 'vue'
import { useRoute } from 'vue-router';

// import components
import FontIcon  from '@/components/core/icon/FontIconComp.vue';


// prepare props and data
const route = useRoute();
const props = defineProps({
  path: String,
  data: Object,
  limit: {
    type: Number,
    default: 10
  }
});
const totalPage = computed(() => {
  let total = parseInt(props.data.count);
  let limit = props.limit;
  let output = Math.trunc(total / limit);

  if((total % limit) != 0) {
    output++;
  }

  return output;
});



const getQuery = (page = 1) => {
  let query = {...route.query};
  query.page = page;
  return query;
}
const getRouter = (page = 1) => {
  return { 
    path: props.path, 
    query: getQuery(page)
  }
}
const getPrevRouter = () => {
  let page = parseInt(props.data.page) - 1;
  return getRouter(page >= 1 ? page : 1);
}
const getNextRouter = () => {
  let page = parseInt(props.data.page) + 1;
  return getRouter(page < totalPage.value ? page : totalPage.value);
}
</script>


<template>
  <div
    class="page__number" 
    v-if=" totalPage > 1 "
  >
    <ul>
      <li>
        <router-link :to="getRouter()" class="page__number__item">
          <FontIcon slug="angle-double-left" />
        </router-link>
      </li>
      <li>
        <router-link :to="getPrevRouter()" class="page__number__item">
          <FontIcon slug="angle-left" />
        </router-link>
      </li>


      <li v-for="n in totalPage" :key="n">
        <router-link
          :to="getRouter(n)" 
          class="page__number__item"
          :class="{ current: n == props.data.page }"
        >
          {{ n }}
        </router-link>
      </li>


      <li>
        <router-link :to="getNextRouter()" class="page__number__item">
          <FontIcon slug="angle-right" />
        </router-link>
      </li>
      <li>
        <router-link :to="getRouter(totalPage)" class="page__number__item">
          <FontIcon slug="angle-double-right" />
        </router-link>
      </li>
    </ul>
  </div>
</template>