<script setup>
import { http } from '@/constant/http';
import { loading } from '@/utilities/display';
import { defineProps } from 'vue'
import { useRouter } from 'vue-router';

const moment = require('moment');

const router = useRouter();
const props = defineProps({
  jobId: {
    type: String,
    default: ''
  },
  code: {
    type: String,
    default: ''
  },
  mode: {
    type: String,
    default: 'title', 
  },
  status: {
    type: Number,
    default: 9
  },
  publishDate: {
    type: String,
    default: ''
  },
  closeInterestStatus: {
    type: Number,
    default: 0
  },
  interestLimit: {
    type: Number,
    default: 3
  },
})


const openJob = async () => {
  const conf = confirm('คุณต้องการเปิดงานนี้อีกครั้งใช่หรือไม่ ?');
  if(!conf) return;
  
  loading();
  await http
        .patch('/job/' + props.jobId, {
          status: 9
        })
        .then(() => router.go(0)) // refresh
        .catch(() => {
          alert('ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง !!!');
          loading();
        });
}

const openInterest = async (status) => {
  // const conf = confirm('คุณต้องการเปิดงานนี้อีกครั้งใช่หรือไม่ ?');
  // if(!conf) return;
  
  loading();
  await http
        .patch('/job/' + props.jobId, {
          close_interest: status
        })
        .then(() => router.go(0)) // refresh
        .catch(() => {
          alert('ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง !!!');
          loading();
        });
}

const calRemainInterestDate = () => {
  if(props.publishDate === '') {
    return -1
  }

  var a = moment();
  var b = moment(props.publishDate);
  const diff = props.interestLimit - a.diff(b, 'days')   // =1
  return diff;
}
</script>


<template>
  <div class="job__code">
    <span 
      class="code"
      :class="{
        closed: status == 5
      }"
    >
      {{ code }}
    </span>
    <div v-if="status > 1 && mode == 'title' && jobId" class="action mt--1">
      <span class="current-status">
        สถานะโครงการ
        <span
          v-if="status == 9"
          class="on"
        >
          <router-link
            :to="'/job/' + jobId + '/close'"
            class="action--btn close"
          >
            สั่งปิด
          </router-link>
        </span>
        <span 
          v-else-if="status == 5"
          class="off"
        >
          ถูกปิดแล้ว
          <a
            href="#"
            class="action--btn open"
            @click.prevent="openJob()"
          >
            สั่งเปิด
          </a>
        </span>
      </span>
      
      <div class="mt--0d5">
        <span class="current-status">
          สถานะการกดสนใจ

          <span 
            v-if="closeInterestStatus == 9"
            class="off"
          >
            ปิดแล้ว
            <a
              href="#"
              class="action--btn open"
              @click.prevent="openInterest(0)"
            >
              สั่งเปิด
            </a>
          </span>
          <span
            v-else-if="calRemainInterestDate() <= 0"
            class="off"
          >
            หมดเวลาแล้ว
            <router-link :to="'/job/edit/' + jobId">
              แก้ไข
            </router-link>
          </span>
          <span
            v-else-if="closeInterestStatus == 0"
            class="on"
          >
            เปิดอยู่ (เหลือ  {{ calRemainInterestDate() }} วัน)
            <a
              href="#"
              class="action--btn close"
              @click.prevent="openInterest(9)"
            >
              สั่งปิด
            </a>
          </span>
        </span>
      
      
      </div>
    </div>
  </div>
</template>

<style scoped>
.action {
  line-height: 20px;
}
.current-status {
  /* display: block; */
}
.current-status > .on {
  color: #30B481;
}
.current-status > .off {
  color: #E55252;
}
.action--btn {
  font-size: 12px;
  line-height: 18px;
  color: #E55252;
}
.action--btn.open {
  color: #30B481;
}
.mt--0d5 {
  margin-top: 5px;
}
</style>