<script setup>
import ProgressList from '@/components/job/detail/progress/ProgressList.vue'
// import GanttBlock from '@/components/job/detail/progress/GanttBlock.vue'
import BillList from '@/components/job/detail/progress/BillList.vue'

import { ref, onMounted, defineProps } from 'vue';


const props = defineProps({ data: Object });
const loaded = ref(false);
const ganttData = ref([]);
const agreement = ref([]);

/** Lift Cycle ----------- */
onMounted(() => {
  if(props.data.agreement[0] !== undefined || props.data.agreement[0].boq_gantt.length > 0) {
    agreement.value = props.data.agreement;
    ganttData.value = agreement.value.boq_gantt;
  }
  loaded.value = true;
})
</script>

<template>
  <div 
    v-if=" loaded "
    class="job__step6"
  >
    <ProgressList :data="data" />
    <BillList     :data="agreement" class="mt--3" />    
    <!-- <GanttBlock   :data="ganttData" class="mt--3" /> -->
  </div>
</template>