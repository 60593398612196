<template>
  <div 
    v-if="showLoading()"
    class="loading"
  >
    <div class="item">
			<div class="loader">
          <p>Processing</p>
          <span></span>
          <span></span>
          <span></span>
      </div>
		</div>
  </div>
</template>

<script>
export default {  methods: {    /**
     * Detect global state for show/hide loading
     */
    showLoading() {
      return this.$store.getters.showLoading;
    },
  }}
</script>

<style scoped>
.loading {  
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.9);
  z-index: 10000;
}
.item {	
  display: block;
	position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50px);
}
.loader{
	margin: 0 auto;
}
p {
  margin-bottom: 5px;
	text-align:center;
	font-size:16px;
	font-weight:200;
	color:#FFF;
	letter-spacing:1px;
}
.loader span {
	display: inline-block;
	position: absolute;
	left: 50%;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	margin-left: -10px;
	animation: 3s infinite linear;
}
.loader span:nth-child(2) {
	background: #E84C3D;
	animation: kiri 1.2s infinite linear;
	
}
.loader span:nth-child(3) {
	background:#F1C40F;
	z-index: 100;
}
.loader span:nth-child(4) {
	background: #2FCC71;
	animation: kanan 1.2s infinite linear;
}


@keyframes kanan {
  0% {
    transform:translateX(25px);
  }
  50% {
    transform:translateX(-25px);
	}
	100% {
    transform:translateX(25px);
    z-index:200;
	}
}

@keyframes kiri {
  0% {
    transform: translateX(-25px);
    z-index: 200;
  }
	50% {
    transform: translateX(25px);
	}
	100% {
    transform: translateX(-25px);
	}
}
</style>