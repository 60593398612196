<script setup>
import SectionTitle from '@/components/display/text/SectionTitle.vue'
import BillListItem from '@/components/job/detail/progress/BillListItem.vue';
import { ref, onMounted, defineProps } from 'vue';



defineProps({ data: Object });
const loaded = ref(false);


/** Lift Cycle ----------- */
onMounted(() => {

  loaded.value = true;
})


/** Method ----------- */

</script>

<template>
  <div 
    v-if=" loaded "
    class="job__step6"
  >
    <SectionTitle title="การชำระเงิน" class="section__main mt--4"></SectionTitle>
    
    <div
      v-for="item in data"
      :key="item"
    >
      <SectionTitle :title="item.name"></SectionTitle>
      <BillListItem 
        v-for="bill in item.bill"
        :key="bill"
        :bill="bill"
      />
    </div>

    <!-- <div class="period__net">
      <div class="label">มูลค่าสัญญารวม</div>
      <div class="value">{{ data.net_amount }} บาท</div>
    </div> -->
    
    <!-- <pre>
      {{data.bill}}
    </pre> -->
  </div>
</template>



