import axios from 'axios';


/**
 * Create Axios instance for send api request
 *  support => GET, POST, PUT, PATCH, DELETE
 */ 
 export const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type':  'application/json',
    'Authorization': '{Bearer} ' + process.env.VUE_APP_AUTH_KEY,
  },
});


/**
 * Create Axios instance for file uploader
 */ 
 export const httpUpload = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': '{Bearer} ' + process.env.VUE_APP_AUTH_KEY,
  },
});


/**
 * create Axios instance for OTP provider
 */ 
 export const httpOTP = axios.create({
  baseURL: process.env.VUE_APP_OTP_APP_URL,
  headers: {
    'Content-Type': 'application/json',
    'api_key':      process.env.VUE_APP_OTP_APP_KEY,
    'secret_key':   process.env.VUE_APP_OTP_APP_SECRET,
  },
});