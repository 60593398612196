export const jobStep = [
  { key: 'detail',      title: 'ข้อมูลรายละเอียด', detail: 'ข้อมูลโครงการ'},
  { key: 'interest',    title: 'ผู้รับเหมาที่สนใจ',   detail: 'ผู้รับเหมาสนใจ'},
  // { key: 'detail',      title: 'ข้อมูลรายละเอียดโครงการ', detail: 'ข้อมูลโครงการ'},
  // { key: 'interest',    title: 'ผู้รับเหมาที่สนใจโครงการ',   detail: 'ผู้รับเหมาสนใจ'},
  { key: 'appointment', title: 'นัดวันเข้าสํารวจหน้างาน',      detail: 'นัดวันสำรวจ'},
  { key: 'survey',      title: 'ข้อมูลสํารวจหน้างาน',     detail: 'ข้อมูลสำรวจ'},
  { key: 'proposal',    title: 'เสนอราคา',  detail: 'การเสนอราคา'},
  { key: 'agreement',   title: 'สัญญาก่อสร้าง',      detail: 'สัญญาก่อสร้าง'},
  { key: 'process',     title: 'ความคืบหน้าของโครงการ',    detail: 'กำลังก่อสร้าง'},
  { key: 'finish',      title: 'โครงการเสร็จสิ้น',     detail: 'โครงการเสร็จสิ้น'},
]


// building type
export const building_type = [
  { id: 1, name: 'บ้านเดี่ยว'},
  { id: 2, name: 'ทาวเฮาส์' },
  { id: 3, name: 'คอนโด' },
  { id: 4, name: 'อาคาร' },
  { id: 5, name: 'สํานักงาน' },
  { id: 6, name: 'ร้านค้า' },
  { id: 7, name: 'โกดัง' },
  { id: 8, name: 'งานโครงการ' },
  { id: 90, name: 'อื่นๆ (ระบุ)' },
];

 
// urgency of job
export const urgency = [
  { id: 10, name: 'เร็วที่สุด' },
  { id: 20, name: '1 - 3 เดือน' },
  { id: 30, name: '4 - 6 เดือน' },
  { id: 40, name: '7 - 12 เดือน' },
  { id: 90, name: 'อื่นๆ' } // (ระบุ)
];

export const facility = [
  { id:'electric', name: 'มีไฟฟ้าแล้ว' },
  { id:'plumbing', name: 'มีประปาแล้ว' },
  { id:'internet', name: 'มีอินเตอร์เน็ตแล้ว' },
];

export const jobDraftData = {
  owner: '',
  parent_cat: '',
  detail: '',
  building_type: null,
  building_type_other: null,
  budget: null,
  urgency: null,
  address: {
    address:     '',
    subDistrict: '',
    district:    '',
    province:    '',
    zipcode:     '',
  },
  status: 1,
}

export const surverDraft = {
  work_day: '',
  work_time: '',
  gate_width: '',
  facility:[],
  condition:[],

  envelopment: '',
  obstacle: '',
  building_permit: '',
  supervisor: '',
  surveyor_opinion: '',
  other: '',
}
export const surverBoqDraft = [
  {
    title: '',
    detail: '',
    value: '',
    unit: '',
    parent: '',
    child: [],
  }
];

export const surveyCondition = [
  { id:'housing_estate',   name: 'เป็นโครงการหมู่บ้านจัดสรร' },
  { id:'camp',          name: 'สามารถพักหน้างานได้' },
  { id:'store_material',name: 'สามารถกองวัสดุหน้างานได้' },
  { id:'truck',  name: 'รถบรรทุกสามารถเข้าได้' },
  { id:'parking',       name: 'มีที่จอดรถ' },
  { id:'work_permit',   name: 'ต้องขออนุญาติก่อสร้าง' },

  // { id:'truck_10wheel', name: 'รองรับรถบรรทุก 10 ล้อ' },
];