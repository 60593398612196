<script setup>
import FontIcon  from '@/components/display/icon/FontIconComp.vue';
import { ref, defineProps, onMounted } from 'vue';


const props = defineProps({ 
  data: Object,
  editable: {
    type: Boolean,
    default: true
  }
})
const item = ref([]);

onMounted(() => {
  item.value = props.data;
})

const onClick = (score) => {
  if(props.editable) item.value.score = score;
}
</script>

<template>
  <div class="job__review__item">
    {{ data.title }}
    <div class="score">
      <a 
        v-for="i in 5"
        :key="i"
        href="#" 
        @click.prevent="onClick(i)"
      >
        <FontIcon 
          slug="star"
          :class="{
            active: i < item.score + 1
          }"
        />
      </a>
    </div>
  </div>
</template>

<style scoped>
/* .job__review__item a {
  margin-left: 5px;
} */
</style>