<script setup>
import Input      from '@/components/display/form/InputRound.vue';


import {ref, reactive, onMounted, defineProps } from 'vue' //, defineExpose
// import { useVuelidate }    from '@vuelidate/core';
// import { required } from '@vuelidate/validators';
// import { http } from '@/utilities/http';
import { facility, surveyCondition } from '@/vars/job.js';


const props = defineProps({ modelValue: Object, });
const loaded = ref(false);
const state = reactive(props.modelValue);

/** Life Cycle ------------------- */
onMounted( async () => {
  loaded.value = true;
})

</script>

<template>
  <div v-if="loaded">
    <div class="row" style="width: 400px">
      <div class="col col--2">
        <Input
          v-model="state.work_day"
          label="วันทำงาน" 
          placeholder="กรุณากรอกวันทำงาน"
          :required="true"
        />
      </div>
      <div class="col col--2">
        <Input
          v-model="state.work_time"
          label="เวลาทำงาน" 
          placeholder="กรุณากรอกเวลาทำงาน"
          :required="true"
        />
      </div>
    </div>
    
    <Input 
      v-model="state.facility"
      label="สาธารณูปโภคภายใน"
      inputType="checklist"
      :option="facility"
    />
    <Input 
      v-model="state.condition"
      label="เงื่อนไข"
      inputType="checklist"
      :option="surveyCondition"
    />

    <div style="width: 200px">
      <Input
        v-model="state.gate_width"
        label="ทางเข้าขนาด (เมตร)" 
        placeholder="ทางเข้าขนาด"
        :required="true"
      />
    </div>

    <Input 
      v-model="state.obstacle"
      label="อุปสรรค์หน้างาน"
      inputType="textarea"
    />
    <Input 
      v-model="state.envelopment"
      label="ลักษณะพื้นที่ข้างเคียง"
      inputType="textarea"
    />
    <Input 
      v-model="state.surveyor_opinion"
      label="ความคิดเห็นจากผู้เข้าสํารวจ"
      inputType="textarea"
    />
    <Input 
      v-model="state.other"
      label="อื่นๆ"
      inputType="textarea"
    />
      
    
    <!-- <pre>{{ state}}</pre> -->
  </div>
</template>