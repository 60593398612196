export const userRoute = [
  {
    path: '/user',
    name: 'UserView',
    component: () => import('@/views/user/UserView.vue'),
    meta: { title: 'รายการผู้ใช้งาน' }
  },
  {
    path: '/user/add',
    name: 'UserAddView',
    component: () => import('@/views/user/UserAddView.vue'),
    meta: { title: 'เพิ่มข้อมูลผู้ใช้งาน' }
  },
  {
    path: '/user/:id/edit/',
    name: 'UserEditView',
    component: () => import('@/views/user/UserEditView.vue'),
    meta: { title: 'แก้ไขข้อมูลผู้ใช้งาน' }
  },
];