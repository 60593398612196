<script setup>
import Wrap      from '@/components/core/layout/WrapComp.vue';
import JobStep from '@/components/job/detail/JobStep.vue'
import JobTitle from '@/components/job/detail/JobTitle.vue'
import Step1 from '@/components/job/detail/step/Step1__General.vue'
// import Step1s from '@/components/job/detail/step/Step1s__FindCont.vue'
import Step2 from '@/components/job/detail/step/Step2__SelectCont.vue'
import Step3 from '@/components/job/detail/step/Step3__Appointment.vue'
import Step4 from '@/components/job/detail/step/Step4__Survey.vue'
import Step5 from '@/components/job/detail/step/Step5__Compare.vue'
// import Step6 from '@/components/job/detail/step/Step6__CreateAgreement.vue'
import Step6 from '@/components/job/detail/agreement/AgreementList.vue'
import Step7 from '@/components/job/detail/step/Step7__Progress.vue'
import Step8 from '@/components/job/detail/step/Step8__Review.vue'
import WarningCard from '@/components/job/module/JobWarningCard.vue'
import NotiBox         from '@/components/core/noti/NotiBoxComp.vue';

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router'; //, useRouter
import { jobStep } from '@/vars/job'
import { http } from '@/utilities/http'
import { loading } from '@/utilities/display';
import { surverDraft, surverBoqDraft } from '@/vars/job.js';
// import { jobList } from '@/vars/dummy/job';

const route = useRoute();
const jobId = route.params.id;
const loaded = ref(false);
const step = ref(1);
const stepList = ref([]);
const data = ref([]);


/** Life Cycle ----------- */
onMounted( async () => {
  await http
        .get('/job/' + jobId + '/full')
        .then((res) => data.value = res.data )
        .catch((err) => {
          console.log(err)
          alert('การดึงข้อมูลผิดพลาด กรุณาลอง refresh ใหม่อีกครั้ง')
        });

  // prepare boq and surver info for old data
  console.log(data.value);
  if(data.value.survey_info.length === 0 ) {
    data.value.survey_info = surverDraft;
    data.value.survey_boq = surverBoqDraft;
  }

  stepList.value = jobStep;

  // find tab when refresh
  if(route.query.step !== undefined) {
    stepList.value.forEach((item, index) => {
      if(route.query.step === item.key) {
        step.value = index+1;
      }
    });
  }

  loaded.value = true;
  loading(false);
})



/** Method ----------- */
const changeStep = (nextStep) => {
  step.value = nextStep
}
</script>

<template>
  <Wrap v-if="loaded" class="job__view">
    <JobTitle :data=" data " />

    <NotiBox
      v-if="data.status == 5"
      title="งานนี้ถูกปิดแล้ว"
      :text="data.closed_remark"
      icon="times-circle"
      iconType="error"
    >
      <router-link
        :to="'/job/' + jobId + '/close'"
        style="color: #900"
      >
      [แก้ไขข้อความ]
      </router-link>
    </NotiBox>

    <JobStep 
      v-if="data.status > 1"
      :data=" data " 
      :currentStep=" step " 
      @change-step=" changeStep "
    />

  
    <Step1 
      v-if="step === 1" 
      :data=" data "
    />
    <Step2
      v-else-if="step === 2" 
      :data=" data "
    />
    
    <Step3
      v-else-if="step === 3" 
      :data=" data "
    />
    <Step4
      v-else-if="step === 4" 
      :data=" data "
    />
    <Step5
      v-else-if="step === 5" 
      :data=" data "
    />

    <div v-else-if="step === 6">
      <WarningCard v-if="data.winner === 0" />
      <Step6
        v-else 
        :data=" data "
      /> 
    </div>

    <Step7
      v-else-if="step === 7" 
      :data=" data "
    />

    <Step8
      v-else-if="step === 8" 
      :data=" data "
    />
    <!-- 
    
     -->
    
    
  </Wrap>
</template>