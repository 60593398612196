<script setup>
// import components
import PageTitle       from '@/components/core/layout/PageTitleComp.vue';
import Title           from '@/components/display/text/SectionTitle.vue';
import Wrap            from '@/components/container/WrapComp.vue';
import Box             from '@/components/container/SectionBox.vue';
import Input           from '@/components/display/form/InputRound.vue';
import InputAddr       from '@/components/display/form/InputAddressSet.vue';
import RequirementForm from '@/components/job/form/JobRequirementForm.vue';
import SurveyInfoForm   from '@/components/job/form/JobSurveyInfoForm.vue';
import GalleryUploader from '@/components/display/file/gallery/GalleryUploaderComp.vue';
import FileUploader    from '@/components/display/file/file/FileUploaderComp.vue';
import ButtonSet3Col   from '@/components/display/form/ButtonSet3Col.vue';
import ButtonSet       from '@/components/display/form/ButtonSet.vue';

import NotiBox         from '@/components/core/noti/NotiBoxComp.vue';
import SurveyBoqForm   from '@/components/job/form/JobSurveyBoqForm.vue';

import { ref, reactive, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useVuelidate }    from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';

// import constant services or utilities
import { jobDraftData, surverDraft, surverBoqDraft } from '@/vars/job.js';
import {loading} from '@/utilities/display'; // 
import { http } from '@/utilities/http';

import getMemberList  from '@/core/service/member/memberGetList.js';
import getUsers       from '@/services/user/getUsers';
import notiAddData        from '@/services/noti/notiAddData'

const moment = require('moment');



// const store = useStore();
// const memberId = ref(store.getters.currentUser.member_id);
const router = useRouter();
const route = useRoute();
const jobId = route.params.id;
const loaded = ref(false);
const originalData = ref([]);
const state = reactive({ 
  job: {
    ...jobDraftData,
    survey_info: surverDraft,
    survey_boq:  surverBoqDraft,
    survey_status: 9,
    publish_status: false
  }
});
const addressRef = ref(null);
const requestRef = ref(null);


/**
 * ? ส่วนนี้เป็นของ admin เท่านั้น
 */
const member     = ref([]);
const user       = ref([]);
const ownerOption= computed(() => {
  let output = [];
  Object.values(member.value).forEach( (item) => {
    output.push({
      id: item.member_id,
      name: item.full_name,
    })
  });
  return output;
})
const filterUserRole = (role) => {
  let output = [];
  Object.values(user.value).forEach( (item) => {
    if(item.role.includes(role)) {
      output.push({
        id: item.user_id,
        name: item.full_name,
      });
    }
  });
  return output;
}
const aeOption = computed( () => filterUserRole('ae') );
const caOption = computed( () => filterUserRole('ca') );
// const pmOption = computed( () => filterUserRole('pm') );




onMounted( async () => {
  /**
   * ? ส่วนนี้เป็นของ admin เท่านั้น
   */
  state.job.ae = '';
  state.job.ca = '';
  state.job.other = '';

  let userRes = await getUsers({limit: 500});
  user.value = userRes.rows;

  // get job item
  await http
        .get('/job/' + jobId + '/full')
        .then(async (res) => {
          let data = res.data;
          originalData.value = data;
          if(data.requirement.detail !== undefined) {
            state.job.job_code      = data.job_code;
            state.job.interest_date_limit = data.interest_date_limit;
            state.job.owner         = data.owner === 0 ? '' : data.owner;
            state.job.ae            = data.ae === 0 ? '' : data.ae;
            state.job.ca            = data.ca === 0 ? '' : data.ca;

            state.job.parent_cat    = data.parent_cat === 0 ? '' : data.parent_cat.term_id;
            state.job.detail        = data.requirement.detail;
            state.job.building_type = data.requirement.type;
            state.job.building_type_other = data.requirement.type_other;
            state.job.budget        = data.requirement.budget;
            state.job.urgency       = data.requirement.urgency;
            state.job.other         = data.requirement.other === undefined ? '' : data.requirement.other;
            state.job.address       = data.address;
            state.job.location      = data.requirement.location;
            state.job.status        = data.status;

            if(Object.values(data.survey_info).length > 0) {
              state.job.survey_info = data.survey_info;
            }
            if(data.survey_boq.length > 0) {
              state.job.survey_boq  = data.survey_boq;
            }

            // can't edit survey info and boq
            if(data.status == 9) {
              state.job.publish_status = true;
            }
            
            
            member.value = [];
            member.value.push(data.owner_meta);
          } else {
            let memberRes = await getMemberList({ limit: 2500 });
            member.value = memberRes.rows;
          }
          // console.log(member.value);

          loaded.value = true;
          loading(false);
        })
        .catch((err) => {
          alert('เกิดข้อผิดพลาด ไม่สามารถโหลดข้อมูลประเภทโครงการได้')
          console.log(err.response)
        });
})

const v$ = useVuelidate({
  interest_date_limit: {
    required,
    numeric
  },
  owner:    {required},
  ae:       {required},
  ca:       {required},
}, state.job);




/** Method ------------------- */
// on submit form
const onSubmit = async () => {
  // Validate data
  await v$.value.$touch();
  await requestRef.value.v$.$touch();
  await addressRef.value.v$.$touch();
  if (v$.value.$invalid || requestRef.value.v$.$invalid || addressRef.value.v$.$invalid) {
    alert('คุณกรอกข้อมูลไม่ถูกต้องหรือไม่ครบถ้วน !!!')
    window.scrollTo(0,0);
    return;
  }
  
  loading();

  // save job detail
  // if success, add noti to owner
  await http 
        .put('/job/' + jobId, state.job)
        .then(async () => {
          await notiAddData(state.job.owner, jobId, 'job_approved');
          router.push('/job/view/' + jobId);
        })
        .catch(() => alert('การบันทึกข้อมูลผิดพลาด กรุณาลองใหม่อีกครั้ง'))
  loading(false);
}

// on click cancel button
const onApprove = async () => {
  state.job.status = 9;
  if(originalData.value.process_status < 4) {
    state.job.process_status = 5;
    state.job.survey_approve_status = 9;
    state.job.publish_date = moment().format("YYYY-MM-DD HH:mm:ss");
  }
  
  if(!state.job.publish_status) {
    state.job.publish_status = true;
    return false;
  }
}

const onCancel = () => {
  router.go(0);
}
</script>


<template>
  <Wrap v-if="loaded" class="mt--2">
    <form 
      @submit.prevent="onSubmit"
      class="form"
    >
      <PageTitle title="โพสโครงการใหม่"></PageTitle>

      <NotiBox
        title="หมายเหตุ"
        text="ผู้ดูแลระบบจำเป็นต้องเลือกฝ่ายขาย วิศวกรที่ปรึกษา และผู้ดูแลโครงการจึงจะกดปุ่มบันทีกหรือเผลแพร่ข้อมูลได้ หากกดเผยแพร่ข้อมูลจะเป็นการอนุมัติเพื่อให้เปิดผู้รับเหมาเริ่มสนใจโครงการนี้ได้"
        icon="exclamation-circle"
      />
      
      <fieldset>
        <Box>
          <Title title="ผู้ที่เกี่ยวข้อง" class="mt--0"></Title>

          <!-- <Input 
            v-model="state.job.job_code"
            label="รหัสโครงการ"
            :required="true"
            :validate="v$.job_code"
            style="width: 200px"
          /> -->
          <Input 
            v-model="state.job.interest_date_limit"
            label="จำนวนวันปิดการกดสนใจ"
            inputType="number"
            :required="true"
            :validate="v$.interest_date_limit"
            style="width: 200px"
          />
          <Input 
            v-model="state.job.owner"
            label="ลูกค้า"
            inputType="select"
            placeholder="กรุณาเลือกลูกค้า - -"
            :option="ownerOption"
            :required="true"
            :validate="v$.owner"
          />
          <Input 
            v-model="state.job.ae"
            label="ฝ่ายขาย"
            inputType="select"
            placeholder="กรุณาเลือกผู้ดูแลโครงการ - -"
            :option="aeOption"
            :required="true"
            :validate="v$.ae"
          />
          <Input 
            v-model="state.job.ca"
            label="วิศวกรที่ปรึกษา"
            inputType="select"
            placeholder="กรุณาเลือกวิศวกรที่ปรึกษา - -"
            :option="caOption"
            :required="true"
            :validate="v$.ca"
          />
        </Box>


        <Box>
          <Title title="รายละเอียดโครงการ" class="mt--0"></Title>
          <RequirementForm 
            v-model="state.job"
            ref="requestRef"
          />
          <InputAddr 
            v-model="state.job.address" 
            addressLabel="สถานที่ตั้งโครงการ"
            ref="addressRef"
          />
          <Input
            v-model="state.job.location"
            label="ลิ้งค์โลเคชั่น"
            placeholder="กรุณากรอกลิ้งค์โลเคชั่น (อาจจะคัดลอกมาจาก google map หรือ line app)"
          />
          <Input 
            v-model="state.job.other"
            inputType="textarea"
            label="อื่นๆ"
          />
        </Box>
        <!-- end: General job infomation -->



        <!--
          OnSite infomation
        -->
        <Box>
          <Title title="รายละเอียดหน้างาน"></Title>
          <SurveyInfoForm
            v-model="state.job.survey_info"
          />
        </Box>
        <!-- end: OnSite infomation -->


        <Box>
          <Title title="อัพโหลดรูปภาพหน้าโครงการ" class="mt--0"></Title>
          <GalleryUploader
            section="job"
            :refer_id="jobId"
            slug="gallery"
          />
        </Box>

        <Box>
          <Title title="แนบไฟล์อื่นๆ" class="mt--0"></Title>
          <FileUploader
            section="job"
            :refer_id="jobId"
            slug="attach"
          />
        </Box>

        <Box>
          <Title title="บัญชีแสดงปริมาณวัสดุ (BOQ)" class="mt--0">
            <p>ปริมาณงานสามารถกรอกได้เฉพาะตัวเลขทศนิยม 2 ตำแหน่งเท่านั้น ไม่สามารถใส่ลูกน้ำในตัวเลขได้</p>
          </Title>
          
          <SurveyBoqForm
            v-model="state.job.survey_boq"
            :required=" state.job.publish_status "
          />
        </Box>


        <div class="mt--2">
          <!-- <ButtonSet3Col 
            v-if="originalData.status != 9"
            :primaryMethod="onApprove"


          /> -->
          {{ state.job.publish_status ? 'submit' : 'button' }}
          <ButtonSet3Col
            v-if="originalData.status != 9"
            submitTitle="บันทึก"
            :primaryMethod="onApprove"
            :primaryType=" state.job.publish_status ? 'submit' : 'button' "
            :cancelMethod="onCancel"
          />
          <ButtonSet 
            v-else
          />
        </div>
      </fieldset>
    </form>
  </Wrap>
</template>