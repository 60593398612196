const numeral = require('numeral');

/**
 * convert number to float
 * 
 * @param {*} num 
 * @returns float
 */
export const toFloat = (num) => {
  return (Math.round(num * 100) / 100);//.toFixed(2);
}


export const priceFormat = (num) => {
  return numeral(num).format('0,0.[00]');
}