import { createStore } from 'vuex'

// import object
import getters from '@/store/getter.js';
import mutations from '@/store/mutations.js';

export default createStore({
  state: {
    // user token
    authToken: '',

    // Display state
    loadingStatus: false,

    // moebile menu toggle
    mobileMenuStatus: false,
  },
  getters: getters,
  mutations: mutations,
})
