<script setup>
import Input      from '@/components/display/form/InputRound.vue';


import {ref, reactive, onMounted, defineProps, defineExpose } from 'vue'
import { useVuelidate }    from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
import { http } from '@/utilities/http';
import { building_type } from '@/vars/job.js';


const props = defineProps({ modelValue: Object, });
const loaded = ref(false);
const state = reactive(props.modelValue);

// variables use for cat selector ------
const cat = ref([]);
const catOpt = ref([]);

// validator object ------
const v$ = useVuelidate({
  owner:         {required},
  ae:            {required},
  ca:            {required},
  parent_cat:    {required},
  detail:        {required},
  building_type: {required},
  budget:        {required, numeric},
  urgency:       {required},
}, state);
defineExpose({ v$ }) /// define variable for parent component


/** Life Cycle ------------------- */
onMounted( async () => {
  // get categories
  await http
        .get('/job/term/cat')
        .then(res => {
          cat.value = res.data.rows
          cat.value.forEach(item => {
            catOpt.value.push({ id: item.term_id, name: item.name });
          })
        })
        .catch(() => {
          alert('เกิดข้อผิดพลาด ไม่สามารถโหลดข้อมูลประเภทงานได้');
        });

  loaded.value = true;
})


/** Mothod ------------------- */
</script>

<template>
  <div v-if="loaded">
    <Input 
      v-model="state.parent_cat"
      label="ประเภทงาน"
      inputType="select"
      placeholder="กรุณาเลือกประเภทงานหลัก - -"
      :option="catOpt"
      :required="true"
      :validate="v$.parent_cat"
    />

    <Input 
      v-model="state.building_type"
      label="ประเภทสิ่งก่อสร้าง"
      inputType="select"
      placeholder="กรุณาเลือกประเภทสิ่งก่อสร้าง - -"
      :option="building_type"
      :required="true"
      :validate="v$.building_type"
      class="input--mb"
    />
    <Input 
      v-if="state.building_type == 90"
      v-model="state.building_type_other"
      label="กรุณาระบุประเภทสิ่งก่อสร้าง"
      placeholder="กรุณาระบุ"
    />
    
    <Input 
      v-model="state.detail"
      inputType="textarea"
      label="ระบุรายละเอียดงาน"
      :required="true"
      :validate="v$.detail"
      :rows="8"
    />
    

    <div class="row">
      <div class="col col--2">
        <Input
          v-model="state.urgency"
          label="กำหนดเริ่มดำเนินการ" 
          placeholder="กรุณาเลือกกำหนดการ"
          :required="true"
          :validate="v$.urgency"
        />
      </div>
      <div class="col col--2">
        <Input
          v-model="state.budget"
          label="งบประมาณ"
          placeholder="กรุณากรอกงบประมาณ"
          :required="true"
          :validate="v$.budget"
        />
      </div>
    </div>
    
    
  </div>
</template>