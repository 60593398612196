import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'

import jwt_decode from "jwt-decode";
import getTokenFromCookie from '@/core/service/secure/getTokenFromCookie';

// import any route
// import { chatRoute } from './routes/chat-route.js'
import { estimateCostRoute } from './routes/estimate-route.js'
import { jobRoute } from './routes/job'
// import { paymentRoute } from './routes/payment-route.js'
import { loginRoute } from './routes/login-route.js'
import { leaveWorkRoute } from './routes/leave-work-route.js'
import { memberRoute } from './routes/member-route.js'
import { userRoute } from './routes/user-route.js'

// import services or utilities
import bodyBg from '@/core/utilities/display/bodyBg.js';
import authen from '@/services/user/authen';


/**
 * Merge route array
 */ 
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { title: 'ภาพรวม' }
  },
  // ...chatRoute,
  ...estimateCostRoute,
  ...jobRoute,
  ...leaveWorkRoute,
  
  ...memberRoute,
  ...loginRoute,
  ...userRoute,
];


/**
 * Initial router
 */
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});




/**
 * use beforeEach method for detect member logged in status
 */
router.beforeEach((to, from, next) => {
  store.commit('setMobileMenuStatus', false);

  // redirect to login page if not logged in 
  // and trying to access a restricted page
  const publicPages = [
    '/login',
    '/forget-password',
    // '/reset-password',
  ];
  const authRequired = !publicPages.includes(to.path);
  const authToken    = getTokenFromCookie();

  // if this page want auth
  // Get new Auth for check current permission
  if(authRequired) {
    authen(); 
  }

  // must check token timeout soon
  if(authToken) jwt_decode(authToken);

  // if no logged in status
  // system will redirect to 'login' page
  // if (authRequired && !authToken) {
  //   return next('/login');
  // }
  
  // reset body background color
  bodyBg(); 

  // set page title
  document.title = ((to.meta.title != undefined) ? to.meta.title : 'No title') + " | ระบบหลังบ้าน | Beaverman";

  // go to next page
  next();
})

export default router
