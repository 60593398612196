<script setup>
import Button from '@/components/display/form/ButtonRound.vue';
import WarningCard from '@/components/job/module/JobWarningCard.vue'
import SearchCont from '@/components/job/detail/select-contractor/SearchCont.vue';

import { ref, onMounted, defineProps } from 'vue';
import { contractor } from '@/vars/dummy/contractor'
import { http } from '@/utilities/http';
import notiAddData from '@/services/noti/notiAddData'

const props = defineProps({ data: Object });
const data = ref([]);
const cont = ref([]);
const loaded = ref(false);


/** Life Cycle ----------- */
onMounted(() => {
  data.value = props.data;
  cont.value = contractor;
  loaded.value = true;
})


/** Method ----------- */
const changeStatus = async (item) => {
  const jobId = props.data.job_id;
  
  // update process status
  if(data.value.process_status >= 6) {
    alert('งานอยู่เกินขั้นตอนการสำรวจแล้ว ไม่สามารถเลือกผู้รับเหมาเข้าสำรวจเพิ่มได้')
    return false;
  }
  if(data.value.process_status < 3) {
    data.value.process_status = 3;
    await http
    .patch('/job/' + jobId, {process_status: 3});
  }

  item.owner_accept = item.owner_accept == 0 ? 9 : 0;
  await http
        .patch(
          '/job/' + jobId + '/interest/' + item.member_id,
          { 
            owner_accept: item.owner_accept,
            appointment_accept: item.owner_accept
          }
        )
        .then((res) => {
          console.log(res.data);
          console.log('interest complete');
          notiAddData(
            item.member_id, 
            jobId, 
            item.owner_accept == 9 ? 'owner_interest' : 'owner_cancel_interest'
          );
        })
        .catch((err) => {
          console.log(err.response);
          alert('บันทึกข้อมูลเกิดข้อผิดพลาด');
        })
}

const avatarImage = (avatar) => {
  if(avatar !== '') {
    return avatar
  }
  return require('@/assets/images/member/no-avatar.svg');
}

const contractorProfileUrl = (member_id) => {
  return process.env.VUE_APP_FRONT_BASE_URL + '/contractor/' + member_id;
}

</script>

<template>
  <div v-if=" loaded ">
    <WarningCard 
      v-if="data.cont.length == 0" 
      title="กำลังรอผู้รับเหมากดสนใจ"
      text="ยังไม่มีผู้รับเหมารายใดกดสนใจงานของท่าน"
      icon="clock-orange"
    />
    <div 
      v-else
      class="selected-cont"
    >
      <div 
        v-for="info in data.cont"
        :key="info"
        class="selected-cont-item"
      >
        <div class="inner">
          <figure 
            class="logo"
            :style="{
              'background-image': 'url('+avatarImage(info.avatar)+')'
            }"
          >
            <!-- <img :src="" /> -->
            <img src="@/assets/images/scale/1-1.png" />
          </figure>
          <h4 class="name">
            <!-- <a href="#">{{ info.contractor.contact.coord }}</a> -->
            <a href="#">{{ info.full_name }}</a>
          </h4>
          <img src="@/assets/images/member/verified-status.svg" class="mt--1" style="margin-bottom: 3px" />

          <!-- <ButtonSetFullWidth   
            :text="info? เลือกเจ้านี้แล้ว" 
            icon="check"
          /> -->
          <div 
            class="
              form__button 
              form__button--full-width
            "
          >
            <Button 
              v-if="info.owner_accept == 0"
              text="เลือก"
              icon=""
              @click.prevent="changeStatus(info)"
            />
            <Button 
              v-else
              text="เลือกแล้ว"
              icon="check"
              class="btn--submit"
              @click.prevent="changeStatus(info)"
            />

            <p class="view-profile">
              <a 
                :href="contractorProfileUrl(info.member_id)" 
                target="_blank"
              >ดูข้อมูลผู้รับเหมา</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <SearchCont 
      :jobId="data.job_id"
      :cont="data.cont"
      class="mt--4"
    />
  </div>
</template>

<style scoped>
.selected-cont {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
}
.selected-cont-item {
  padding: 5px;
}
.selected-cont-item .inner {
  padding: 20px;
  border-radius: 20px;
  width: 220px;
  box-shadow: 0 0 8px rgba(0,0,0,0.1);
}
.selected-cont-item .inner:hover {
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  /* margin-top: -20px;
  transition: ease-in margin-top 0.1s; */
}
.selected-cont-item h4 a {
  display: block;
  margin: 5px 0 0 0;
  font-weight: 400px;
  font-size: 18px;
  color: #333;
}
.logo {
  display: block;
  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.logo img {
  width: 100%;
  opacity: 0.0;
}
.view-profile {
  margin-top: 5px;
  text-align: center;
  font-weight: normal;
}
</style>