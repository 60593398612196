export const navigator = [
  {
    title:  'ภาพรวม',
    path:   '/',
    icon:   'braille',
  },
  {
    title:  'จัดการโครงการ',
    path:   '/job',
    icon:   'city',
    child: [
      { title: 'โครงการทั้งหมด', path: '/job' },
      { title: 'จัดการการชำระเงิน', path: '/bill' },
      { title: 'จัดการหน่วยวัด', path: '/job/manage/unit' },
    ],
    toggleBy: [
      'JobView',
      'JobDetail',
      'CreateDraftJob',
      'JobPostView',
      'JobPostEditView',
      'JobEditView',

      'AllJobBill',
      'BillFormView',

      'ManageUnit',
    ]
  },
  {
    title:  'จัดการระบบประเมิน',
    path:   '/estimate-cost',
    icon:   'user',
    child: [
      { title: 'จัดการระบบประเมิน', path: '/estimate-cost' },
      { title: 'จัดการสูตรการคำนวน', path: '/estimate-cost/calculate-type' },
    ],
    toggleBy: [
      'EstimateCatView',
      'EstimateCatFormView',
      'EstimateSubCatFormView',
      'EstimateCostCalculateTypeView',
    ]
  },
  {
    title:  'จัดการผู้ใช้งาน',
    path:   '/member',
    icon:   'user',
  },
  {
    title:  'จัดการผู้รับเหมา',
    path:   '/member/contractor',
    icon:   'user',
    child: [
      { title: 'จัดการผู้รับเหมา', path: '/member/contractor' },
      { title: 'จัดการตรา', path: '/member/contractor/badge' },
      { title: 'เงื่อนไขผู้รับเหมา', path: '/member/contractor/term' },
    ],
    toggleBy: [
      'MemberContView',
      'ProfileContractorView',
      'ContractorProfileEditView',

      'MemberContBadgeView',
      'MemberContBadgeEditView',

      'TermFormView',
    ]
  },
  {
    title:  'จัดการผู้ดูแลระบบ',
    path:   '/user',
    icon:   'user-circle',
  },
  {
    title:  'ผู้รับเหมาทิ้งงาน',
    path:   '/leave-work',
    icon:   'ban',
  },
  {
    title:  'ออกจากระบบ',
    path:   '/login',
    icon:   'power-off',
  },
];