/**
 * Check authentication from API server.
 *    if success, set new token to store.
 *    if auth fail, alert message and redirect to login.
 */
import { http } from '@/constant/http.js';
import store from '@/store';

export default async function authen() {
  await http
        .post('/user/auth/', {
          token: store.getters.token
        })
        .then((res) => {
          store.commit('setAuthToken', res.data.token);
        })
        .catch(() => {
          alert("ขณะนี้ชื่อผู้ใช้งานของท่านเกิดปัญหาหรือสิทธิ์การใช้งานของท่านถูกระงับ กรุณาลงชื่อเข้าใช้งานใหม่อีกครั้ง");
          window.location.href = process.env.VUE_APP_ADMIN_BASE_URL + '/login';
        });
}