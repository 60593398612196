<script setup>
import JobCode from '../module/JobCode.vue';
import People from '../module/JobPeople.vue';
import { defineProps } from 'vue';

const props = defineProps({ 
  data: Object,
  codeMode: {
    type: String,
    default: 'title'
  }
})

const copyPreviewLink = () => {
  navigator.clipboard.writeText(
    process.env.VUE_APP_MEMBER_BASE_URL + '/job/view/' + props.data.job_id
  );
  alert('copied')
}
</script>


<template>
  <div class="job__head">
    <p 
      v-if="data.status == 1"
      class="status--wait--publish"
    >
      รอการอนุมัติ
    </p>
    
    <h2 class="job__head__title">
      {{ data.name }}
      <span class="copy-preview-link">
        <a @click.prevent="copyPreviewLink" href="#">
          <i class="fa fa-link"></i>
          Copy link
        </a>
      </span>
    </h2>
    
    <JobCode 
      v-if="data.status > 1" 
      :jobId="data.job_id"
      :code="data.job_code" 
      :mode="codeMode"
      :status="data.status"
      :closeInterestStatus="data.close_interest"

      :publishDate="data.publish_date"
      :interestLimit="data.interest_date_limit"
    />
    <JobCode v-else 
      code="รอการอนุมัติ"
      style="padding-right: 0; background: none; color: #E55252;"
    />

    <ul class="job__people" style="margin-top: 8px">
      <People :info="data.owner_meta" :showName="true" type="owner" />
      <People v-if="data.ae !== 0" :info="data.ae_meta" :showName="true" />
      <People v-if="data.ca !== 0"  :info="data.ca_meta" :showName="true" type="ca" />
      <!-- <People v-if="data.pm !== 0"  :info="data.pm_meta" :showName="true" type="pm" /> -->
      <!-- <People v-if="data.winner !== 0"  :info="data.winner_meta" type="ca" /> -->
    </ul>
  </div>
</template>

<style scoped>
.job-interest {
  bottom: 15px;
}
.status--wait--publish {
  display: block;
  color: #ac3000;
  font-size: 13px;
  font-weight: bold;
}
.copy-preview-link {
  font-size: 14px;
  color: #ccc;
}
</style>