/**
 * All route manage data that relate job 
 */

 export const jobManageRoute = [
   // manage unit
   {
     path: '/job/manage/unit',
     name: 'ManageUnit',
     component: () => import('@/views/job/unit/JobUnitView.vue'),
     meta: {
      title: 'จัดการหน่วยวัด'
     }
   },
 ];