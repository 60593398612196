<script setup>
import { loadImage } from '@/utilities/image'
import VueEasyLightbox from 'vue-easy-lightbox';

import { ref, onMounted, defineProps } from 'vue';




const props = defineProps({ 
  image: Object
});
const loaded = ref(false);
const images = ref([]);
const popupIndex = ref(0);
const popupVidible = ref(false);


/** Lift Cycle ----------- */
onMounted(() => {
  // console.log(props.image);
  Object.values(props.image).forEach(image => {
    images.value.push( image.uri );
  })

  loaded.value = true;
})


/** Method ----------- */
const imageItem = (index) => {
  return loadImage(images.value[index] === undefined ? '' : images.value[index]);
}
const showPopup = (index) => {
  if(images.value[index] !== undefined) {
    popupIndex.value = index;
    popupVidible.value = true
  }
}

const hidePopup = () => {
  popupVidible.value = false
}
</script>


<template>
  <div 
    v-if=" loaded "
    class="job__gallery"
  >
    <div class="col__main">
      <a 
        @click.prevent="showPopup(0)"
        href="#" 
        class="job__gallery__item"
        :style="{ backgroundImage: 'url(' + imageItem(0) + ')' }"
      >
        <img :src="imageItem(0)" />
      </a>
    </div>
    <div class="col__second">
      <a 
        @click.prevent="showPopup(1)"
        href="#" 
        class="job__gallery__item"
        :style="{ backgroundImage: 'url(' + imageItem(1) + ')' }"
      >
        <img :src="imageItem(1)" />
      </a>
    </div>

    <div class="col__third">
      <a 
        @click.prevent="showPopup(2)"
        href="#" 
        class="job__gallery__item"
        :style="{ backgroundImage: 'url(' + imageItem(2) + ')' }"
      >
        <img :src="imageItem(2)" />
      </a>
    </div>

    <a 
      v-if="images.length > 3"
      @click.prevent="showPopup(3)"
      href="#" 
      class="show__all"
    >+{{ images.length - 3 }}</a>
  </div>

  
  <VueEasyLightbox
    scrollDisabled
    escDisabled
    moveDisabled
    :visible="popupVidible"
    :loop="true"
    :imgs="images"
    :index="popupIndex"
    @hide="hidePopup"
  />
</template>