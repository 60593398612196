/**
 * All route for post job
 *   CreaeDraftJob - pcreate job draft data 
 */

import JobPostView from '@/views/job/post/JobPostFormView.vue';

export const jobPostRoute = [
  // create draft job data
  {
    path: '/job/draft',
    name: 'CreateDraftJob',
    component: () => import('@/views/job/post/CreateDraftJob.vue')
  },

  // post job
  {
    path: '/job/post/:id',
    name: 'JobPostView',
    component: JobPostView,
    meta: { 
      title: 'โพสงานใหม่'
    }
  },
  {
    path: '/job/edit/:id',
    name: 'JobPostEditView',
    component: JobPostView,
    meta: { 
      title: 'แก้ไขข้อมูลงาน'
    }
  },

  // post job
  // {
  //   path: '/job/post/:id/',
  //   name: 'JobPostView',
  //   component: JobPostView,
  //   meta: { 
  //     title: 'โพสงานใหม่'
  //   }
  // },

  // edit post job
  // from draft job detail page or job view step 1
  {
    path: '/job/edit/:id',
    name: 'JobEditView',
    component: JobPostView,
    meta: { 
      title: 'แก้ไขรายละเอียดงาน'
    }
  },
  {
    path: '/job/edit/:id/proposal/:member_id',
    name: 'JobEditProposalView',
    component: () => import('@/views/job/JobEditProposalView.vue'),
    meta: { 
      title: 'แก้ไขรายละเอียดใบเสนอราคา'
    }
  },



  {
    path: '/job/:id/agreement/:agreement_id',
    name: 'ViewAgreement',
    component: () => import('@/views/job/agreement/JobAgreement.vue'),
    meta: { title: 'ข้อมูลสัญญา' }
  },



  /** job close form */
  {
    path: '/job/:id/close',
    name: 'JobCloseFormView',
    component: () => import('@/views/job/post/JobCloseFormView.vue'),
    meta: { title: 'ปิดโครงการ' }
  },
  
];