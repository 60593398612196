<script setup>
import {defineProps} from 'vue';


const props = defineProps({
  src: {
    type: String,
    default: ''
  }
})

const avatarImage = () => {
  return props.src !== '' ? props.src : require('@/assets/images/member/no-avatar.svg');
}
</script>

<template>
  <figure 
    class="avatar"
    :style="{
      backgroundImage: 'url(' + avatarImage() + ')'
    }"
  >
    <img src="@/assets/images/scale/1-1.png" />
  </figure>
</template>
