<script setup>
// import Avatar from '@/components/display/image/AvatarComp.vue';
import { ref, onMounted, defineProps } from 'vue';

defineProps({info:Object})
// const route = useRoute();
const loaded = ref(false);


/** Life Cycle ----------- */
onMounted(() => {
  loaded.value = true;
})
</script>


<template>
  <div 
    v-if="loaded" 
    class="cont__box"
  >
    <div class="inner">
      <figure class="logo">
        <img v-if=" info.avatar != ''" :src="info.avatar" />
        <img v-else src="@/assets/images/scale/1-1.png" />
      </figure>
      <h4 class="name">
        <a href="#">{{ info.full_name }}</a>
      </h4>
      <img 
        src="@/assets/images/member/verified-status.svg" 
        class="mt--1" 
        style="margin-bottom: 3px; opacity: 0.0" 
      />
    </div>
    <slot></slot>
  </div>
</template>

<!-- <script setup>
// import Avatar from '@/components/display/image/AvatarComp.vue';
import { ref, onMounted, defineProps } from 'vue';
import { useRoute } from 'vue-router'; //, useRouter
// import { contentImage } from '@/utilities/image'
import { contractor } from '@/vars/dummy/contractor'

defineProps({info:Object})
const route = useRoute();
const loaded = ref(false);
const data = ref([]);


/** Life Cycle ----------- */
onMounted(() => {
  data.value = route.params.id === 'BM-0513' ? contractor[0] : contractor[2];
  loaded.value = true;
})
</script>


<template>
  <div 
    v-if="loaded" 
    class="cont__box"
  >
    <div class="inner">
      <figure class="logo">
        <img :src="info.avatar" />
      </figure>
      <h4 class="name">
        <a href="#">{{ info.full_name }}</a>
      </h4>
      <img src="@/assets/images/member/verified-status.svg" class="mt--1" style="margin-bottom: 3px" />
    </div>
    <slot></slot>
  </div>
</template> -->