import {apiRequest} from '@/constant/api.js';

export default async function getMemberListAPI(params=[]) {
  let member = {};
  await apiRequest
    .get(
      '/member/',
      {params: params}
    )
    .then((res) => {
      member = res.data;
    });
  return member;
}