<script setup>
import InfoRow from '@/components/display/info/InfoRow.vue'

import { defineProps } from 'vue';
import buildingType from '@/services/job/buildingType'
import addressFormat     from '@/utilities/format/addressFormat'
import nl2br     from '@/utilities/format/nl2br'
const numeral = require('numeral');

defineProps({ 
  data: Object,
  hideWrap: {
    type: Boolean,
    default: false,
  }
});
</script>

<template>
  <div>
    <InfoRow title="ประเภทงาน">
      {{ data.parent_cat.name }}
    </InfoRow>
    <InfoRow title="ประเภทสิ่งก่อสร้าง">
      {{ buildingType( data.requirement.type ) }}
      <span v-if="data.requirement.type == 90">
        - {{data.requirement.type_other}}
      </span>
    </InfoRow>

    <div class="detail-block">
      <h4 class="title">รายละเอียด</h4>
      <div v-html="nl2br(data.requirement.detail)"></div>
    </div>
    
    <InfoRow title="กำหนดเริ่มดำเนินการ">
      {{ data.requirement.urgency }}
    </InfoRow>

    <InfoRow title="งบประมาณ">
      {{ numeral(data.requirement.budget).format('0,0') }} บาท
    </InfoRow>

    <InfoRow title="ที่อยู่">
      {{ addressFormat(data.address) }}
    </InfoRow>

    <InfoRow title="อื่นๆ">
      <div v-html="nl2br(data.requirement.other)"></div>
    </InfoRow>
  </div>
</template>

<style>
.detail-block {
  margin-bottom: 32px;
  padding: 32px 0 40px 0;
  border-top: solid 1px #e9e9e9;
  border-bottom: solid 1px #e9e9e9;
}
.detail-block .title {
  font-size: 15px;
  font-weight: 600;
  /* color: #999; */
}
</style>
