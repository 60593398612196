<script setup>
// import components
import Title           from '@/components/display/text/SectionTitle.vue';
import Box             from '@/components/container/SectionBox.vue';
import InputAddr       from '@/components/display/form/InputAddressSet.vue';
import RequirementForm from '@/components/job/form/JobRequirementForm.vue';
import SurveyInfoForm  from '@/components/job/form/JobSurveyInfoForm.vue';
import SurveyBoqForm   from '@/components/job/form/JobSurveyBoqForm.vue';
import GalleryUploader from '@/components/display/file/gallery/GalleryUploaderComp.vue';
import FileUploader    from '@/components/display/file/file/FileUploaderComp.vue';
import ButtonSet3Col       from '@/components/display/form/ButtonSet3Col.vue';
import ButtonSet       from '@/components/display/form/ButtonSet.vue';

import { ref, reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { jobDraftData, surverDraft, surverBoqDraft } from '@/vars/job.js';
import { loading } from '@/utilities/display'; // 
import { http } from '@/utilities/http';



/** Common variables ------------------- */
const router = useRouter();
const route = useRoute();
const jobId = route.params.id;
const loaded = ref(false);
const editable = ref(true);
const originalData = ref([]);
const state = reactive({ 
  job: {
    ...jobDraftData,
    survey_info: surverDraft,
    survey_boq:  surverBoqDraft,
    publish_status: false
  }
});
const addressRef = ref(null);
const requestRef = ref(null);
// const boqFormRef = ref(null);


/** Life Cycle ------------------- */
onMounted( async () => {
  // get job item
  await http
        .get('/job/' + jobId +'/full')
        .then(res => {
          let data = res.data;
          originalData.value = data;

          // this content is already key in from first step
          state.job.owner         = data.owner;
          state.job.parent_cat    = data.parent_cat === 0 ? '' : data.parent_cat.term_id;
          state.job.detail        = data.requirement.detail;
          state.job.building_type = data.requirement.type;
          state.job.building_type_other = data.requirement.type_other;
          state.job.budget        = data.requirement.budget;
          state.job.urgency       = data.requirement.urgency;
          state.job.address       = data.address;
          state.job.location      = data.requirement.location;
          state.job.process_status = data.process_status;
          console.log(data);
          // alert(state.job.process_status);
          
          if(Object.values(data.survey_info).length > 0) {
            state.job.survey_info = data.survey_info;
          }
          if(data.survey_boq.length > 0) {
            state.job.survey_boq  = data.survey_boq;
          }

          // can't edit survey info and boq
          if(data.process_status >= 5) {
            state.job.publish_status = true;
          }

          // if job process status is 'agreement step (step 6)'
          // can't edit survey info and boq
          if(data.process_status >= 6) {
            editable.value = false;
          }

          loaded.value = true;
          loading(false);
        })
        .catch((err) => {
          console.log(err);
          alert('เกิดข้อผิดพลาด ไม่สามารถโหลดข้อมูลประเภทงานได้')
        });
})




/** Method ------------------- */

// on submit form
const onSubmit = async () => {
  // alert('---');

  // Validate data
  // await requestRef.value.v$.$touch();
  await addressRef.value.v$.$touch();
  if (addressRef.value.v$.$invalid) return; //requestRef.value.v$.$invalid || 
  
  let data = {...state.job};
  if(data.process_status < 4) {
    data.process_status = 4;
  }
  
  // if survey save after customer approve
  // process status is fixed to step 5
  // if(originalData.value.survey_status == 9) {
  //   data.process_status = 5;
  // } else {
  //   // if original survey status is not 9
  //   // and publish_status is true 
  //   // change survey_status = 5;
    if(data.publish_status) {
      data.survey_status = 9;
      data.process_status = 5;
    }
  // }
  delete data.status; // delete data.owner;

  loading();
  await http 
        .put('/job/' + jobId, data)
        .then( () => router.go(0))
        .catch((err) => {
          console.log(err.response);
          alert('การบันทึกข้อมูลผิดพลาด กรุณาลองใหม่อีกครั้ง');
        })
  loading(false);
}

const onApprove = async () => {
  if(!state.job.publish_status) {
    state.job.publish_status = true;
    return false;
  }
  
  const conf = confirm('คุณต้องการเผยแพร่ข้อมูลการสํารวจใช่หรือไม่ ?');
  if(!conf) return false;

  // state.job.survey_status = 5;

  // boqFormRef.value.submit();
  
  // state.job.process_status = 5;
  // await onSubmit();
}
// on click cancel button
const onCancel = () => {
  router.go(0);
}
</script>


<template>
  <form 
    v-if="loaded"
    @submit.prevent="onSubmit"
    class="form"
  >    
    <fieldset>

      <Box>
        <Title title="รายละเอียดงาน" class="mt--0"></Title>
        <RequirementForm 
          v-model="state.job"
          ref="requestRef"
        />
        <InputAddr 
          v-model="state.job.address" 
          addressLabel="สถานที่ตั้งโครงการ"
          ref="addressRef"
        />
      </Box>

      <Box>
        <Title title="รายละเอียดหน้างาน" class="mt--0"></Title>
        <SurveyInfoForm
          v-model="state.job.survey_info"
          ref="requestRef"
        />
      </Box>
      
      

      <Box>
        <Title title="อัพโหลดรูปภาพหน้างาน" class="mt--0"></Title>
        <GalleryUploader
          section="job"
          :refer_id="jobId"
          slug="gallery"
        />
      </Box>

      <Box>
        <Title title="แนบไฟล์อื่นๆ" class="mt--0"></Title>
        <FileUploader
          section="job"
          :refer_id="jobId"
          slug="attach"
        />
      </Box> 

      
      <Box>
        <Title title="บัญชีแสดงปริมาณวัสดุ (BOQ)" class="mt--0">
          <p>ปริมาณงานสามารถกรอกได้เฉพาะตัวเลขทศนิยม 2 ตำแหน่งเท่านั้น ไม่สามารถใส่ลูกน้ำในตัวเลขได้</p>
        </Title>
        
        <SurveyBoqForm
          v-model="state.job.survey_boq"
          :required=" state.job.publish_status "
        />
      </Box>


      <div 
        v-if="editable"
        class="mt--2"
      >
        <ButtonSet3Col
          v-if="originalData.survey_status != 9"
          submitTitle="บันทึก"
          :primaryMethod="onApprove"
          :primaryType=" state.job.publish_status ? 'submit' : 'button' "
          :cancelMethod="onCancel"
        />
        <ButtonSet 
          v-else
        />
      </div>
    </fieldset>
  </form>
</template>