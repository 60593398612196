<script setup>
import BoqTr from './BoqTr.vue';
// import SVGIcon from '@/components/display/icon/SVGIconComp.vue';
import FontIconComp from '@/components/display/icon/FontIconComp.vue';
import { ref, onMounted, computed, defineProps, defineEmits } from 'vue';


const emit = defineEmits([
  'decrease-indent',
  'increase-indent',
  'add-row',
  'remove-row',
]);
const props = defineProps({
  index: Number,
  parent: String,
  data: Object,
  unit: Object,
  required: {
    type: Boolean,
    default: true
  },
  editable: {
    type: Boolean,
    default: true
  }
});
const item = ref([]);
const childParent = ref('');
const hasChild = computed(() => {
  if(item.value.child=== undefined) {
    return true
  } else {
    return item.value.child.length > 0
  }
});


/** Life Cycle -----------------------*/
onMounted(() => {
  item.value = props.data;

  if (props.parent === '-1') {
    childParent.value = '';
  } else if(props.parent !== '') {
    childParent.value = props.parent + '.';
    item.value.parent = childParent.value;
  }
})





/** Method -----------------------*/

/**
 * Decrease item
 */
const decreaseItem = (currentItem, index = 1, parentIndex = -1, depth = -2) => {
  try {
    // if depth is current depth, pass data to parent depth
    if(depth === -2) {
      let currentItemParent = currentItem.parent.replace(/.$/,'')
      let indexToRoot = currentItemParent.split('.');
      let lastIndex = parseInt(indexToRoot[indexToRoot.length-1]);
      console.log(lastIndex);
      emit('decrease-indent', currentItem, index, lastIndex, -1);
    } 
    else if(depth === -1) {
      // get item from same level that index more than currentItem index
      // to currentIndex child
      if(index < item.value.child.length -1) {
        console.log('new child');
        console.log(item.value.child.slice(index+1));

        // set new current child
        currentItem.child = [
          ...currentItem.child,
          ...item.value.child.slice(index+1)
        ];

        // remove item that moved to current child
        item.value.child = item.value.child.slice(0, index); 
      }


      // if depth is parent depth, pass data to grand parent depth to process
      emit('decrease-indent', currentItem, index, parentIndex, 0);

      // remove child that move out
      item.value.child.splice(index, 1);
    } 
    else {
      // set new item parent slug
      currentItem.parent = props.parent === '-1' ? '' : childParent.value + (index+1);

      // insert to grand parent depth array by specific index
      item.value.child.splice(parentIndex, 0, currentItem);
    }
  } catch(err) {
    console.log('decrease error');
    console.log(err);
  }
}


/**
 * increase item
 */
const increaseItem = (currentItem, index) => {
  let currentItemParent = currentItem.parent;
  // console.log(currentItem);

  try{
    // if had parent code, remove last dot (.)
    if(currentItemParent.length > 0) {
      currentItemParent = currentItemParent.replace(/.$/,'')
    }

    // if it's not depth to process, emit to parent
    if(props.parent === currentItemParent) {
      emit('increase-indent', currentItem, index);
    } else {
      // process for indent list
      const newItem = {...currentItem};
      item.value.child.splice(index, 1); // remove old item
      item.value.child[index-1].child.push(newItem); // push to new position
    }
  } catch(err) {
    console.log('increase error');
    console.log(err);
  }
}


/**
 * add item below
 */
const addItemBelow = (index, depth = -1) => {
  const childTemplate = {
    title: '',
    detail: '',
    value: '',
    unit: '',
    parent: '',
    child: [],
  };
  if(depth === -1) {
    // had child add item to first chld
    if(item.value.child.length > 0) {
      item.value.child.splice(0, 0, childTemplate);
    } else {
      emit('add-row', index, 0);
    }
  }
  else {
    item.value.child.splice(index+1, 0, childTemplate);
  }
}


/**
 * remove item by pass index emit to removeChild in parent method
 */
const removeItem = (index) => {
  emit('remove-row', index);
}

/**
 * remove child
 */
const removeChild = (index) => {
  item.value.child.splice(index, 1);
}


/**
 * Generate Number Indent class
 */
 const numberIndentClass = () => {
  if(item.value.parent === undefined) return '';
  
  return 'pl--' + (item.value.parent.split(".").length - 1 )
}
</script>

<template>
  <tr v-if=" index !== -1">
    <td>
      <div :class="numberIndentClass()">
        {{ childParent + (index+1) }}</div>
    </td>
    <td>
      <div v-if="editable">
        <input type="text" v-model="item.name" />
        
        <div class="control">
          
          <a 
            @click.prevent="decreaseItem(item, index)"
            v-if="parent !== ''"
            href="#"
            title="Decrease indent"
            class="decrease--indent"
          >
            <FontIconComp font="la" size="lg" slug="caret-square-left" />
            <!-- Decrease -->
          </a>
  
          <a 
            v-if="index > 0"
            @click.prevent="increaseItem(item, index)"
            href="#"
            title="Increase indent"
            class="inecrease--indent"
          >
            <FontIconComp font="la" size="lg" slug="caret-square-right" />
            <!-- Increase -->
          </a>
  
          <a 
            @click.prevent="addItemBelow(index)"
            href="#"
            title="Add item below"
            class="add"
          >
            <FontIconComp font="la" size="lg" slug="plus-square" />
            <!-- Add -->
          </a>
  
          <a 
            @click.prevent="removeItem(index)"
            href="#"
            title="Remove current row"
            class="remove"
          >
            <FontIconComp font="la" size="lg" slug="trash" />
            <!-- remove -->
          </a>
          <!-- {{  required  }} -->
        </div>
      </div>
      <div v-else>
        {{ item.name }}
      </div>
    </td>
    <td>
      <div v-if="!hasChild">
        <input 
          v-if="editable"
          type="text" 
          v-model="item.detail"
        />
        <span v-else>
          {{ item.detail }}
        </span>
      </div>
    </td>
    <td>
      <div v-if="!hasChild">
        <input 
          v-if="editable"
          type="number"
          min="0.01"
          step="0.01"
          :required="required"
          v-model="item.value"
        />
        <span v-else>
          {{ item.value }}
        </span>
      </div>
    </td>
    <td>
      <div v-if="!hasChild">
        <select 
          v-if="editable"
          v-model="item.unit"
          :required="required"
        >
          <option value="">เลือก -</option>
          <option 
            v-for="u in unit"
            :key="u"
            :value="u.name"
          >
            {{ u.name }}
          </option>
        </select>
        <span v-else>
          {{ item.unit }}
        </span>
      </div>
      
    </td>
  </tr>

  <BoqTr
    v-for="(child, childIndex) in item.child"
    :key="child"
    :data="child"
    :index="childIndex"
    :parent="parent === '-1' ? '' : childParent + (index+1)"
    :unit="unit"
    :editable="editable"
    :required="required"
    @decrease-indent="decreaseItem"
    @increase-indent="increaseItem"
    @add-row="addItemBelow"
    @remove-row="removeChild"
  />
</template>