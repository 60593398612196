<script setup>
import Box       from '@/components/core/layout/BoxComp.vue';
import PageTitle from '@/components/core/layout/PageTitleComp.vue';
import Paging    from '@/components/core/module/PagingComp.vue';

// import dependencies
import { ref, reactive, watchEffect, onMounted, defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// import services and utilities
import getMemberList from '@/core/service/member/memberGetList.js';
import notiAddData from '@/services/noti/notiAddData'
import { loading } from '@/utilities/display';
import { http } from '@/constant/http';




// Prepare data
const prop = defineProps({
  jobId: String,
  cont: Object
})
const route = useRoute()
const router = useRouter()
const loaded = ref(false);
const user = ref({
  count: 0,
  total: 0,
  rows: []
});
const state = reactive({
  filter: {
    s: '',
  }
})

const tplTableTH = `<tr>
              <th></th>
              <th>ชื่อ-นามสกุล</th>
              <th>เบอร์โทร</th>
              <th>&nbsp;</th>
            </tr>`;


const loadData = async () => {
  loading();
  loaded.value = false;

  // prepare query params
  let params = { 
    c_verify: 9,
    limit: 10,
    role: 'contractor'
  };
  if(route.query.s !== undefined) {
    params.s = route.query.s;
    state.filter.s = params.s;
  }
  if(route.query.page !== undefined) {
    params.page = route.query.page;
  }
  console.log(params);
  user.value = await getMemberList(params);

  user.value.rows.forEach(item => {
    const interested = prop.cont.find(c => {
      console.log(c);
      console.log(item.member_id)
      return c.member_id == item.member_id
    });
    console.log(interested);
    item.interested = interested !== undefined;
  })
  
  loaded.value = true;
  loading(false);
};
onMounted(() => {
  loaded.value = true;
});
watchEffect( loadData )

// Prepare method
const getEditRoute = (id) => {//
  return `/member/${id}/profile`;
}

const onSearch = () => {
  const query = Object.assign({}, route.query);
  query.s = state.filter.s;
  delete query.limit;
  delete query.page;  
  
  router.push({ query });
}

const clickInterest = async (item) => {
  const conf = confirm('คุณต้องการเลือก '+item.full_name+' ใช่หรือไม่ ?')
  if(!conf) return false;

  const jobId = prop.jobId;
  const memberId = item.member_id;
  await http
        .post(
          '/job/select/contractor',
          {
            job_id: jobId,
            member_id: memberId
          }
        )
        .then(async () => {
          await notiAddData(
            memberId, 
            jobId, 
            'contractor_interest'
          );
          router.go(0);
        })
        .catch(() => {
          alert('บันทึกข้อมูลเกิดข้อผิดพลาด');
        })
}
</script>

<template>
  <div>
    <PageTitle title="ค้นหาผู้รับหมา"></PageTitle>
      
    <Box 
      v-if="loaded" 
      :innerHide="true"
    >
      <form 
        @submit.prevent="onSearch"
        class="list__filter"
        autocomplete="off"
      >
        <fieldset>
          <div class="input-wrap">
            <input 
              v-model="state.filter.s" 
              type="text"
              placeholder="ค้นชื่อ, อีเมล์, เบอร์โทร ..."
              autocomplete="false"
            >
          </div>

          <button>
            <i class="fa fa-search"></i>
          </button>
        </fieldset>
      </form>


      <div v-if="user.count == 0" class="table__empty">
        ไม่พบข้อมูล
      </div>
      <table v-else class="table">
        <colgroup>
          <col style="width: 60px;" />
          <col />
          <col />
          <col style="width: 150px;"/>
        </colgroup>
        <thead v-html="tplTableTH"></thead>
        <tbody>
          <tr v-for="item in user.rows" :key="item.member_id">
            <td>
              <router-link :to="getEditRoute(item.member_id)">
                <figure 
                  class="thumbnail"
                  :style="{
                    backgroundImage: 'url('+item.avatar+')'
                  }"
                >
                  <img src="@/assets/images/gallery/scale/1-1.png" alt="">
                </figure>
              </router-link>
            </td>
            <td>
              <router-link 
                :to="getEditRoute(item.member_id)"
                class="highlight"
              >
                {{ item.full_name }}
              </router-link>

              <ul class="action">
                <li>
                  <router-link 
                    :to="getEditRoute(item.member_id)" 
                    target="_blank" 
                    class="edit"
                  >
                    ดูข้อมูล
                  </router-link>
                </li>
              </ul>
            </td>
            <td>
              <a :href="'tel:' + item.phone">{{ item.phone }}</a>
            </td>
            <td>
              <!-- @click.prevent="" -->
              <a 
                v-if="!item.interested"
                @click.prevent="clickInterest(item)"
                href="#"
                class="btn-interesting"
              >
                เลือกผู้รับเหมา
              </a>
              <span v-else class="btn-interested">
                เลือกแล้ว
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot v-html="tplTableTH"></tfoot>
      </table>

      <Paging path="/member/contractor" :data="user" />
    </Box>
  </div>
</template>

<style scoped>
.thumbnail {
  display: block;
  border: solid 1px #eee;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  background-color: #eee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.thumbnail img {
  width: 100%;
  opacity: 0.0;
}
.btn-interesting {
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  background: #30B481;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.3);
}
.btn-interested {
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  background: #e9e9e9;
  color: #999;
}
</style>