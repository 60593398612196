<script setup>
/**
 * Button set for general form
 *    This contain 'submit' and 'cancel' button
 *    - submit active with onSubmit method from parent component
 *    - cancel will refresh current page by 'onCancel' methond
 * 
 * @param {submitTitle} - text in title button
 * @param {cancelTitle} - text in cancel button
 * 
 */

import Button from '@/components/display/form/ButtonRound.vue';
import { ref, defineProps } from 'vue';
import { useRouter }   from 'vue-router';


/// initial variables
const router = useRouter();
const submitRef = ref(null);
const props = defineProps({
  primaryTitle: {
    type: String,
    default: 'เผยแพร่'
  },
  primaryType: {
    type: String,
    default: 'button'
  },
  primaryIcon: {
    type: String,
    default: 'check'
  },
  primaryDisable: {
    type: Boolean,
    default: false
  },
  primaryMethod: {
    type: Function
  },

  submitTitle: {
    type: String,
    default: 'บันทึก'
  },
  submitIcon: {
    type: String,
    default: 'save'
  },

  cancelTitle: {
    type: String,
    default: 'ยกเลิก'
  },
  cancelIcon: {
    type: String,
    default: 'cancel'
  },
  cancelMethod: {
    type: Function
  },
});

// on cancel method
const onApprove = () => {
  if (props.primaryMethod !== undefined) {
    props.primaryMethod();
  }
}

// on cancel method
const onCancel = () => {
  if (props.cancelMethod !== undefined) {
    props.cancelMethod();
  } else {
    router.go();
  }
}
</script>


<template>
  <div class="form__button row">
    
    <div class="col col--3">
      <Button 
        @click="onCancel"
        :text="props.cancelTitle" 
        :icon="props.cancelIcon"
        type="button"
      />
    </div>


    <div class="col col--3">
      <Button 
        type="submit"
        ref="submitRef"
        :text="props.submitTitle"
        :icon="props.submitIcon"
        class="btn--submit"
      />
    </div>
    

    <div class="col col--3">
      <Button 
        @click="onApprove"
        :text="props.primaryTitle"
        :icon="props.primaryIcon"
        class="btn--save"
        :type="primaryType"
        :disabled="primaryDisable"
      />
    </div>
  </div>
</template>