export const contentImage = (path) => {
  return require('@/assets/content' + path);
}

export const loadImage = (url='') => {
  if(url === undefined || url === '') {
    return require('@/assets/images/job/no-image.svg');
  } else {
    return url;
  }
}