<script setup>
import BoqTr from './table/BoqTr.vue'
import {ref, reactive, onMounted, defineProps } from 'vue'


const loaded = ref(false);
const props = defineProps({ 
  modelValue: Object,
  editable: {
    type: Boolean,
    default: true
  }
});
const state = reactive({
  child: []
});
const unit = ref([]);

/** Life Cycle ------------------- */
onMounted( async () => {
  state.child = props.modelValue;
  loaded.value = true;
})
</script>

<template>
  <div 
    v-if="loaded" 
    class="j-tb__boq"
  >
    <table class="tb">
      <colgroup>
        <col style="width: 100px" />
        <col style="width: 200px" />
        <col />
        <col style="width: 100px" />
        <col style="width: 100px" />
      </colgroup>
      <thead>
        <tr>
          <th>ลำดับ</th>
          <th>หัวข้อ</th>
          <th>รายละเอียด</th>
          <th>ปริมาณงาน</th>
          <th>หน่วย</th>
        </tr>
      </thead>
      <tbody class="boq__group"> 
        <BoqTr
          :data="state"
          :index="-1"
          :unit="unit"
          parent="-1"

          :editable="editable"
        />
      </tbody>
    </table>

    <!-- <pre>
      {{ state.child }}
    </pre> -->
  </div>
</template>