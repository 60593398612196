<script setup>
import FontIconComp  from '@/components/core/icon/FontIconComp.vue';

import { ref, watch, onMounted } from 'vue';
// import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { navigator }  from '@/constant/admin/navigator';

const route = useRoute();
// const store = useStore();
const navigators = ref([]);

onMounted(() => {
  navigators.value = navigator;

  /**
   * ! now disable
   * เพระผู้ใช้งานยังไม่ชินกับ flow
   */ 
  // const adminRole = store.getters.currentUser.role;
  // const isAdmin = adminRole.find(role => role === 'admin');
  // const isCustomerService = adminRole.find(role => role === 'cs');
  // const isAE = adminRole.find(role => role === 'ae');
  // const isCA = adminRole.find(role => role === 'ca');
  // const isPM = adminRole.find(role => role === 'pm');
  // const isAcc = adminRole.find(role => role === 'ac');

  // if (isAdmin) {
  //   navigators.value = navigator;
  // } else if(isCustomerService || isAE || isCA || isPM) {
  //   navigators.value = navigator.filter(item => {
  //     return item.path !== '/user';// && (isAE && item.path !== '/estimate-cost')
  //   });
  // } else if(isAcc) {
  //   navigators.value.push(navigator[0]);

  //   const jobItem = {...navigator[1] };
  //   const jobItemChild = [
  //     {...jobItem.child[1]}
  //   ];
  //   jobItem.path = jobItemChild[0].path;
  //   jobItem.child = jobItemChild;
  //   console.log(jobItem);
  //   navigators.value.push(jobItem);

  //   navigators.value.push(navigator[ navigator.length - 1 ]);
  // } else {
  //   navigators.value.push(navigator[0]);
  //   navigators.value.push(navigator[ navigator.length - 1 ]);
  // }
  // console.log(navigators.value);
})

watch(() => route.name, () => {
  navigators.value.forEach(item => {
    item.toggled = item.path == route.path;

    // if has child
    if(item.child !== undefined && item.toggleBy !== undefined) {
      item.toggled = item.toggleBy.find(name => name == route.name) !== undefined;
    }
  })
});

const toggleManu = (item) => {
  navigators.value.forEach(item => {
    item.toggled = false;
  })
  
  if(item.child !== undefined) {
    item.toggled = true;
  }
}
</script>

<template>
  <nav class="sidebar__nav">
    <ul>
      <li 
        v-for="nav in navigators"
        :key="nav.path"
        :class="{ 
          toggled: nav.toggled === true
        }"
      >
        <router-link 
          :to="nav.path"
          class="sidebar__nav__link"
          :class="{
            active: nav.toggled === true
          }"
          @click="toggleManu(nav)"
        >
          <FontIconComp 
            :slug="nav.icon" 
            font="las"
            class="sidebar__nav__icon"
          />
          <span class="sidebar__nav__title">
            {{ nav.title }}
          </span>
        </router-link>

          <!-- Submenu -->
          <ul 
            v-if=" nav.child !== undefined "
            class="sidebar__nav__child"
          >
            <li v-for="child in nav.child" :key="child">
              <router-link :to="child.path">
                {{ child.title }}
              </router-link>
            </li>
          </ul>
          <!-- end: Submenu -->
      </li>
    </ul>
  </nav>
</template>