<script setup>
import ContractorInfo from '@/components/job/module/ContractorBoxInfo.vue'
import ReviewItem     from '@/components/job/detail/review/JobReviewItemComp.vue'
import WarningCard from '@/components/job/module/JobWarningCard.vue'
import InfoRow from '@/components/display/info/InfoRow.vue'

import { ref, onMounted, defineProps } from 'vue';
import { loading } from '@/utilities/display';


const props = defineProps({ data: Object });
const loaded = ref(false);
const isReviewStep = ref(false);
const totalScore = ref(0);



/** Life Cycle ----------- */
onMounted(() => {
  // detect review step
  let noneWithdrawBill = props.data.agreement[0].bill.filter(item => item.withdraw_status != 9).length
  if(noneWithdrawBill === 0) {
    isReviewStep.value = true;
  }
  
  if(props.data.review.score !== undefined) {
    Object.values(props.data.review.score).forEach(item => {
      totalScore.value += item.score;
    })
    totalScore.value = parseFloat(totalScore.value / 3).toFixed(1);
  }

  loaded.value = true;
  loading(false)
})


</script>

<template>
  <ContractorInfo 
    v-if="loaded"
    :info="data.winner_meta" 
    class="had-slot"
  >

    <WarningCard v-if=" !isReviewStep " />

    <div 
      v-else
      class="job__review"
    >
      <WarningCard
        v-if=" data.review.length === 0 "
        title="รอลูกค้ารีวิว"
        text="งานของท่านดำเนินการเสร็จสิ้นแล้ว กำลังรอลูกค้าให้คะแนนรีวิว"
        icon="clock"
      />
      <div v-else>
        <div class="score-title">คะแนนรีวิว</div>
        <div class="score-result">{{totalScore}} / 5.0</div>
        <ReviewItem  
          v-for="item in data.review.score"
          :key="item"
          :data="item"
          :editable="false"
        />
        <InfoRow 
          title="ความคิดเห็น"
          class="mt--2"
        >
          {{data.review.comment}}
        </InfoRow>
      </div>
    </div>

    
  </ContractorInfo>

</template>

<style scoped>
.review__content ol {
  padding-left: 25px;
}
.review__content li {
  list-style-type: decimal;
}
</style>