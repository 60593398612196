<script setup>
/**
 * File list item component
 * 
 * @description
 *    show file item data such as 
 *      - extension icon, 
 *      - file name
 *      - uploaded date
 *    this component can remove file item with 
 *    api connection too.
 * 
 */
import Icon  from '@/components/display/icon/SVGIconComp.vue';
import { ref, defineProps } from 'vue';
import deleteFileItem  from '@/services/file/deleteFileItem.js';


/**
 * Initial common variables
 */
const el = ref(); // this component dom
const onRemove = ref(false);
const props = defineProps({ 
  data: Object,
  hideRemove: {
    type: Boolean,
    default: false
  }
});

/**
 * Remove this file item
 */
const removeItem = async () => {
  let conf = confirm(`คุณต้องการลบไฟล์นี้ใช่หรือไม่ ?\n(${props.data.name})`);
  if(conf) {
    // change component status to 'onRemove'
    onRemove.value = true;

    // call api
    // let res = 
    await deleteFileItem(props.data.file_id);
    el.value.remove();
  }
}
</script>

<template>
  <div 
    ref="el" 
    class="file__list__item"
    :class="{
      removing: onRemove
    }"
  >
    <div class="inner">
      <h5 class="file__name">
        <a 
          :href="props.data.uri"
          target="_blank"
          class="file__name__link"
        >
          {{ props.data.name }}
        </a>
      </h5>
      <p class="upload__date">
        อัพโหลดวันที่ {{ props.data.uploaded_date }}
      </p>
      <Icon icon="file-attach"></Icon>

      <a 
        v-if=" !onRemove && !props.hideRemove "
        @click.prevent="removeItem"
        href="#" 
        class="file__list__item__remove"
      >
        ลบรายการนี้
      </a>
    </div>
  </div>
</template>