export const estimateCostRoute = [
  {
    path: '/estimate-cost',
    name: 'EstimateCatView',
    component: () => import('@/views/estimate-cost/EstimateCatView.vue'),
    meta: { title: 'จัดการระบบประเมิน' }
  },
    {
      path: '/estimate-cost/:id',
      name: 'EstimateCatFormView',
      component: () => import('@/views/estimate-cost/EstimateCatFormView.vue'),
      meta: { title: 'หมวดหมู่' }
    },
      {
        path: '/estimate-cost/:parent/:id',
        name: 'EstimateSubCatFormView',
        component: () => import('@/views/estimate-cost/EstimateSubCatFormView.vue'),
        meta: { title: 'หมวดหมู่ย่อย' }
      },
  
  
  {
    path: '/estimate-cost/calculate-type',
    name: 'EstimateCostCalculateTypeView',
    component: () => import('@/views/estimate-cost/CalculateTypeView.vue'),
    meta: { title: 'จัดการสูตรการคำนวน' }
  },
];