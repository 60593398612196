<script setup>
import FontIcon from '@/components/display/icon/FontIconComp.vue';
import { ref, defineProps, onMounted } from 'vue';


defineProps({
  status: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: ''
  }
})
const loaded = ref(false);


/** Lift Cycle ----------- */
onMounted(() => {
  loaded.value = true
})
</script>

<template>
  <span 
    class="status"
    :class=" status ? 'status-approve' : 'status-wait'"
  >
    <FontIcon :slug="status ? 'check':'remove'" />
    {{ title }}อนุมัติ
    <!-- {{status ? '':'ยังไม่'}}
    {{status ? 'แล้ว':''}} -->
  </span>
</template>

<style scoped>
.status {
  display: inline-block;
  margin-right: 5px;
  padding: 0 10px 0 8px;
  border-radius: 20px;
  /* background: #f5f5f4; */
  background: #fff;
}
.status-wait {
  border: solid 1px #E55252;
}
.status-wait i {
  color:#E55252;
}
.status-approve {
  border: solid 1px #30B481;
  background: #fff;
}
.status-approve i {
  color:#30B481;
}
</style>