/**
 * Ger current member profile by stored token
 */
import store from '@/store';

export default function currentUser( scope = 'member_id' ) {
  let output = '';
  let data = store.getters.currentUser;

  if(scope === 'role') {
    output = data[scope][0];
  } else {
    output = data[scope];
  }

  return output;
}