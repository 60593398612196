/**
 * send noti by notiTypeSlug
 */
import { http } from "@/utilities/http";
import { notiType } from './var';

export default async function notiAddData(memberId, jobId, typeCode) {
  // find noti type by code
  const type = notiType.find(item => item.slug === typeCode);
  if(type === undefined) {
    console.log('Notification type not found !!!');
    return false;
  }

  // send noti
  return await http
        .post('/noti', {
          type:       type.id,
          member_id:  memberId,
          job_id:     jobId,
        });
}