<script setup>
/**
 * File list item component
 * 
 * @description
 *    show file item data such as 
 *      - extension icon, 
 *      - file name
 *      - uploaded date
 *    this component can remove file item with 
 *    api connection too.
 * 
 */
import { ref, defineProps } from 'vue';
import deleteFileItem  from '@/services/file/deleteFileItem.js';


/**
 * Initial common variables
 */
const el = ref(); // this component dom
const onRemove = ref(false);
const props = defineProps({ 
  data: Object,
  hideRemove: {
    type: Boolean,
    default: false
  }
});

/**
 * Remove this file item
 */
const removeItem = async () => {
  let conf = confirm(`คุณต้องการลบไฟล์นี้ใช่หรือไม่ ?\n(${props.data.name})`);
  if(conf) {
    // change component status to 'onRemove'
    onRemove.value = true;

    // call api
    // let res = 
    await deleteFileItem(props.data.file_id);
    el.value.remove();

    // if complete remove file element from dom
    // else disable 'onRemove' status
    // if (res.status == 200) {
    // } else {
    //   onRemove.value = true;
    //   alert(`ไฟล์ชื่อ "${props.data.name}" ไม่สามารถลบได้ !!!`);
    // }
  }
}
</script>

<template>
  <div
    ref="el" 
    class="gallery__item"
  >
    <div class="gallery__item__wrap">
      <a 
        :style="{
          backgroundImage: 'url(' + data.uri + ')'
        }"
        href="#" 
        class="gallery__item__link"
      >
        <img src="@/assets/images/scale/3-2.png">
      </a>
      <a 
        v-if=" !onRemove && !props.hideRemove "
        @click.prevent="removeItem"
        href="#" 
        class="gallery__item__remove"
      >
        <i class="las la-trash" style="font-size: 18px"></i>
      </a>
    </div>
  </div>
</template>