<template>
  <div 
    class="layout"
    :class="{'full-width': !hasLoggedInStatus() }"
  >
    <!-- Main Sidebar -->
    <div v-if="hasLoggedInStatus()" class="layout--nav">
      <div class="inner">
        <Sidebar />
      </div>
    </div>

    <!-- Main Content -->
    <div class="layout--main">
      <div>
        <router-view />
        <div id="after-main-layout"></div>
      </div>
    </div>
  </div>
  <Loading />
  
</template>

<script>
// import components
import Sidebar from '@/components/layout/sidebar/SidebarComp.vue';
import Loading from '@/components/core/module/LoadingOverlayComp.vue';

// load global css
import './assets/css/font/awesome/style.css';
import './assets/css/style.css';

export default {
  methods: {
    /*
    *  Detect logged in session for show 'Sidebar' and 'Breadcrumb'
    */
    hasLoggedInStatus() {
      return this.$store.getters.loggedIn;
    },
  },
  components: {
    Sidebar,
    Loading,
  },
}
</script>