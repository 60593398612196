export const loginRoute = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/LoginView.vue'),
    meta: { title: 'เข้าสู่ระบบ' }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import('@/views/login/ForgetPasswdView.vue'),
    meta: { title: 'ลืมรหัสผ่าน' }
  },
];