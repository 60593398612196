<script setup>
import CompareTr   from '../../form/table/CompareTr.vue';
import CompareExtraTr   from '../../form/table/CompareExtraTr.vue';
import ContractorColumnInfo from '@/components/job/module/ContractorColumnInfo.vue';
import SVGIcon from '@/components/display/icon/SVGIconComp.vue';
import WarningCard from '@/components/job/module/JobWarningCard.vue'
import ButtonRound from '@/components/display/form/ButtonRound.vue';

import { ref, reactive, onMounted, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { loading } from '@/utilities/display';
import { http } from '@/constant/http';
import { priceFormat } from '@/utilities/number';


// const numeral = require('numeral');

const router = useRouter()
const props = defineProps({ data: Object });
const loaded = ref(false);
const state = reactive({
  boq: {
    child: []
  }
});
const cont = ref([]);

/** Lift Cycle ----------- */
onMounted(() => {
  if(props.data.survey_boq.length > 0) {
    state.boq.child = props.data.survey_boq;
    cont.value = props.data.cont.filter(item => item.proposal_status == 9 && item.total > 0);
  }
  console.log(cont.value);

  loading(false);
  loaded.value = true;
})




/** Method ----------- */
const removeWinner = async () => {
  let conf = confirm('คุณต้องการยกเลิกผู้รับเหมาคนนี้ใช่หรือไม่ ?');
  if(conf) {
    loading();
    await http
          .put('/job/' + props.data.job_id + '/reset-winner')
          .then(res => {
            console.log(res.data);
            console.log(router);
            router.go(0)
          })
    loading(false);
  }
}

const changeShowStatus = async (member) => {
  let conf = confirm('คุณต้องการ'+(member.show_status == 9 ? 'ปิด':'เปิด')+'การแสดงผลผู้รับเหมาคนนี้ใช่หรือไม่ ?');
  if(conf) {
    loading();
    await http
          .patch(
            '/job/' + props.data.job_id + '/interest/' + member.member_id, { 
              show_status: member.show_status == 9 ? 0 : 9
            }
          )
          .then(res => {
            console.log(res.data);
            console.log(router);
            router.go(0)
          })
    loading(false);
  }
}

const exportData = async () => {
  loading();
  await http
        .get('/job/' + props.data.job_id + '/export/compare-proposal')
        .then(res => {
          window.location.href = res.data.url;
        })
  loading(false);
}
</script>

<template>
  <div 
    v-if=" loaded "
    class="job__step4"
  >
    <WarningCard 
      v-if="data.survey_status != 9" 
    />
    <WarningCard 
      v-else-if="cont.length === 0" 
      icon="clock-gray"
      title="ผู้รับเหมากำลังเสนอราคา"
    />
    <div v-else class="jtb jtb__proposal">
      
      <div class="mb--2 txt--right">
        <!-- :routePath="'/job/edit/' + data.job_id"  -->
        <ButtonRound 
          text="Export Excel"
          class="btn--active btn--export"
          @click="exportData()"
        />
      </div>

      <table class="tb">
        <colgroup>
          <col style="width: 50px" />
          <col style="width: 280px" />
          <!-- <col style="width: 80px" /> -->
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">ลำดับ</th>
            <th>รายละเอียด</th>
            <!-- <th>ปริมาณงาน</th> -->
            <td
              v-for="c in cont"
              :key="c"
            >
              <ContractorColumnInfo :data=" c " />
              
              <div v-if="data.winner === c.member_id">
                <span class="winner--icon">
                  <SVGIcon icon="check-green" />
                  เลือกเจ้านี้แล้ว
                </span>
                &nbsp;
                &nbsp;
                <ButtonRound
                  v-if="data.process_status == 5 || data.process_status == 6 || data.process_status == 7"
                  @click.prevent="removeWinner "
                  type="button"
                  text="ยกเลิก"
                  icon="cancel"
                  class="btn--submit btn--s btn--cont--action"
                />
              </div>

              <div v-if="data.process_status == 5">
                <ButtonRound
                  :routePath="'/job/edit/' + data.job_id + '/proposal/' + c.member_id" 
                  type="button"
                  text="แก้ไข"
                  icon="edit-file"
                  class="btn--active btn--s btn--cont--action"
                />
                <ButtonRound
                  type="button"
                  :text="c.show_status == 9 ? 'แสดงผลปกติ' : 'การแสดงผลถูกปิด'"
                  icon="eye"
                  class="btn--active btn--s btn--cont--action btn-show-status"
                  :class="{
                    closed: c.show_status < 9
                  }"
                  @click="changeShowStatus(c)"
                />
              </div>
            </td>
          </tr>
          
          <CompareTr
            :data="state.boq"
            :cont="cont"
            :index="-1"
            :editable="data.winner == 0"
            parent="-1"
          />
          <CompareExtraTr
            :cont="cont"
            :index="state.boq.child.length"
            :editable="data.winner == 0"
            parent="-1"
          />

          <tr class="foot">
            <th scope="row" colspan="2">รวม</th>
            <td v-for="p in cont" :key="p">
              {{ priceFormat(p.total) }}
            </td>
          </tr>
          <tr class="foot">
            <th scope="row" colspan="2">ส่วนลด</th>
            <td v-for="p in cont" :key="p">
              {{ priceFormat(p.discount) }}
            </td>
          </tr>
          <tr class="foot">
            <th scope="row" colspan="2">คงเหลือ</th>
            <td v-for="p in cont" :key="p">
              {{ priceFormat(p.after_discount) }}
            </td>
          </tr>
          <tr class="foot">
            <th scope="row" colspan="2">Vat.</th>
            <td v-for="p in cont" :key="p">
              <span v-if="p.vat_inc">{{ priceFormat(p.vat) }}</span>
              <span v-else>-</span>
            </td>
          </tr>
          <tr class="foot-net">
            <th scope="row" colspan="2">มูลค่างานสุทธิ</th>
            <td v-for="p in cont" :key="p">
              {{ priceFormat(p.net_amount) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end: BOQ -->
    <!-- <pre>
      {{cont[0]}}
    </pre> -->
  </div>
</template>

<style scoped>
.btn--cont--action {
  padding: 3px 10px !important;
  font-size: 14px;
}
.btn--export {
  font-weight: 500;
}
.btn--submit {
  
  background: #f60;
}
.btn-show-status {
  background: #f5f5f4;
}
.btn-show-status.closed {
  background: #f60;
}
/* .btn--edit {
  display: inline-block;
  margin-top: 10px;
  padding-bottom: 5px;
  background-color: #FFC62B;
} */
.winner--icon {
  position: relative;
  padding: 0 0 10px 26px;
  font-weight: bold;
  color: #30B481;
}
.winner--icon .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}
</style>